import './UserProfile.css';
import React from 'react';
import { Navigate, Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import { useUserContext } from '../../contexts/UserProvider/UserProvider';

function UserProfile() {
  const { user, isLoggedIn } = useUserContext();
  const [profileData, changeProfileData] = React.useState({ ...user });
  const [usersAccessRequests, changeUsersAccessRequests] = React.useState([]);

  const [lpHistory, setLpHistory] = React.useState([]);

  const [filterDate, setFilterDate] = React.useState('');
  const [filterType, setFilterType] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(5);

  React.useEffect(() => {
    const fetchLPsHistory = async () => {
      if (!profileData.lpsCreated) return;

      const lpData = profileData.lpsCreated;
      const lpHistoryArray = [];

      // Iterate through each LP type
      for (const lpType in lpData) {
        if (filterType === '') {
          if (Object.prototype.hasOwnProperty.call(lpData, lpType)) {
            const lpTypeData = lpData[lpType];

            // Iterate through each date within the LP type
            for (const date in lpTypeData) {
              if (filterDate === '') {
                if (Object.prototype.hasOwnProperty.call(lpTypeData, date)) {
                  const lpItems = lpTypeData[date];
                  // Check if lpItems is an object
                  if (typeof lpItems === 'object' && lpItems !== null) {
                    // Iterate over lpItems entries
                    Object.entries(lpItems).forEach(([key, value]) => {
                      lpHistoryArray.push({
                        date,
                        type: lpType,
                        content: value,
                      });
                    });
                  } else {
                    // If lpItems is not an object, it's a single item
                    lpHistoryArray.push({
                      date,
                      type: lpType,
                      content: lpItems,
                    });
                  }
                }
              } else if (filterDate === date) {
                if (Object.prototype.hasOwnProperty.call(lpTypeData, date)) {
                  const lpItems = lpTypeData[date];
                  // Check if lpItems is an object
                  if (typeof lpItems === 'object' && lpItems !== null) {
                    // Iterate over lpItems entries
                    Object.entries(lpItems).forEach(([key, value]) => {
                      lpHistoryArray.push({
                        date,
                        type: lpType,
                        content: value,
                      });
                    });
                  } else {
                    // If lpItems is not an object, it's a single item
                    lpHistoryArray.push({
                      date,
                      type: lpType,
                      content: lpItems,
                    });
                  }
                }
              }
            }
          }
        } else if (filterType !== '') {
          if (Object.prototype.hasOwnProperty.call(lpData, lpType) && filterType === lpType) {
            const lpTypeData = lpData[lpType];

            // Iterate through each date within the LP type
            for (const date in lpTypeData) {
              if (filterDate === '') {
                if (Object.prototype.hasOwnProperty.call(lpTypeData, date)) {
                  const lpItems = lpTypeData[date];
                  // Check if lpItems is an object
                  if (typeof lpItems === 'object' && lpItems !== null) {
                    // Iterate over lpItems entries
                    Object.entries(lpItems).forEach(([key, value]) => {
                      lpHistoryArray.push({
                        date,
                        type: lpType,
                        content: value,
                      });
                    });
                  } else {
                    // If lpItems is not an object, it's a single item
                    lpHistoryArray.push({
                      date,
                      type: lpType,
                      content: lpItems,
                    });
                  }
                }
              } else if (filterDate === date) {
                if (Object.prototype.hasOwnProperty.call(lpTypeData, date)) {
                  const lpItems = lpTypeData[date];
                  // Check if lpItems is an object
                  if (typeof lpItems === 'object' && lpItems !== null) {
                    // Iterate over lpItems entries
                    Object.entries(lpItems).forEach(([key, value]) => {
                      lpHistoryArray.push({
                        date,
                        type: lpType,
                        content: value,
                      });
                    });
                  } else {
                    // If lpItems is not an object, it's a single item
                    lpHistoryArray.push({
                      date,
                      type: lpType,
                      content: lpItems,
                    });
                  }
                }
              }
            }
          }
        }
      }

      // Set the LPs history
      setLpHistory(lpHistoryArray);
    };

    fetchLPsHistory();
  }, [filterDate, filterType, profileData]);

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = lpHistory.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  React.useEffect(() => {
    firebase
      .database()
      .ref('/users')
      .on('value', (data) => {
        if (data.val()) {
          changeUsersAccessRequests(
            Object.entries(data.val()).map((newData) => ({ ...newData[1], id: newData[0] })),
          );
        }
      });
  }, []);

  React.useEffect(() => {
    changeProfileData({ ...user });
  }, [user]);

  const onAcceptClick = (usr) => {
    if (usr.access === 'pending') {
      firebase.database().ref(`/users/${usr.id}/access`).set('approved');
    } else if (user.access === 'approved') {
      firebase.database().ref(`/users/${usr.id}/access`).set('pending');
    }
  };

  const onRejectClick = (usr) => {
    if (usr.access === 'pending') {
      firebase.database().ref(`/users/${usr.id}/access`).set('rejected');
    } else if (usr.access === 'rejected') {
      firebase.database().ref(`/users/${usr.id}/access`).set('pending');
    }
  };

  return (
    <div className='App App-header flex flex-row w-full justify-center'>
      {isLoggedIn === false ? (
        <Navigate to='/login' />
      ) : (
        user.access === 'approved' && (
          <div className='flex flex-col max-w-screen-xl w-full'>
            <div className='flex flex-col w-full max-w-screen-xl pl-4 pr-4 py-6'>
              <h1 className='text-4xl my-6'>Profile</h1>
              <div className='flex w-full'>{profileData.email}</div>
            </div>

            {/* LPs Created History Section */}
            <div className='flex flex-col w-full max-w-screen-xl pl-4 pr-4 py-6'>
              <h3 className='text-4xl mt-4 mb-2'>LPs Created History</h3>
              <div className='flex flex-row items-center mb-4'>
                <div className='filterContainer'>
                  <label htmlFor='filterDate' className='mr-2'>
                    Filter by Date:
                    <input
                      type='date'
                      id='filterDate'
                      value={filterDate}
                      onChange={(e) => setFilterDate(e.target.value)}
                      className='px-2 py-1 border rounded mx-4'
                    />
                  </label>
                </div>
                <div className='filterContainer'>
                  <label htmlFor='filterType' className='mr-2'>
                    Filter by Type:
                    <select
                      id='filterType'
                      value={filterType}
                      onChange={(e) => setFilterType(e.target.value)}
                      className='px-2 py-1 border rounded mx-4'
                      style={{ color: '#000' }}
                    >
                      <option value=''>All</option>
                      <option value='event'>Event</option>
                      <option value='webinar'>Webinar</option>
                    </select>
                  </label>
                </div>
              </div>
              <div className='overflow-x-auto'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                        Date
                      </th>
                      <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                        Type
                      </th>
                      <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                        BMT ID
                      </th>
                      <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                        Vanity URL
                      </th>
                    </tr>
                  </thead>
                  <tbody className='bg-white divide-y divide-gray-200'>
                    {currentItems.map((item, index) => (
                      <tr
                        key={item.date + item.content.bmtID + item.content.vanity}
                        className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
                      >
                        <td className='px-6 py-4 whitespace-nowrap'>{item.date}</td>
                        <td className='px-6 py-4 whitespace-nowrap'>{item.type}</td>
                        <td className='px-6 py-4 whitespace-nowrap'>{item.content.bmtID}</td>
                        <td className='px-6 py-4 whitespace-nowrap'>{item.content.vanity}</td>
                        <td className='px-6 py-4 whitespace-nowrap'>
                          <Link
                            to={{ pathname: `/${item.type}` }}
                            state={{ data: item.content }}
                            className='default-link'
                          >
                            View Details
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='flex justify-end mt-4'>
                <nav
                  className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'
                  aria-label='Pagination'
                >
                  <button
                    type='button'
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1 || lpHistory.length === 0}
                    className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium ${
                      currentPage === 1 || lpHistory.length === 0 ? 'endPage' : 'default-link'
                    }`}
                  >
                    <span className='sr-only'>Previous</span>
                    {/* Heroicon name: solid/chevron-left */}
                    <svg
                      className='h-5 w-5'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      aria-hidden='true'
                    >
                      <path
                        fillRule='evenodd'
                        d='M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 111.414 1.414L6.414 9H15a1 1 0 110 2H6.414l3.293 3.293a1 1 0 010 1.414z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </button>
                  {[...Array(Math.ceil(lpHistory.length / itemsPerPage)).keys()].map((number) => (
                    <button
                      type='button'
                      key={number}
                      disabled={currentPage === number + 1}
                      onClick={() => paginate(number + 1)}
                      className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
                        currentPage === number + 1 ? 'disabled currentPage' : 'default-link'
                      }`}
                    >
                      {number + 1}
                    </button>
                  ))}
                  <button
                    type='button'
                    onClick={() => paginate(currentPage + 1)}
                    disabled={
                      currentPage === Math.ceil(lpHistory.length / itemsPerPage) ||
                      lpHistory.length === 0
                    }
                    className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium ${
                      currentPage === Math.ceil(lpHistory.length / itemsPerPage) ||
                      lpHistory.length === 0
                        ? 'endPage'
                        : 'default-link'
                    }`}
                  >
                    <span className='sr-only'>Next</span>
                    {/* Heroicon name: solid/chevron-right */}
                    <svg
                      className='h-5 w-5'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      aria-hidden='true'
                    >
                      <path
                        fillRule='evenodd'
                        d='M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L13.586 11H5a1 1 0 010-2h8.586l-3.293-3.293a1 1 0 010-1.414z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </button>
                </nav>
              </div>
            </div>

            {user.isAdmin === true ? (
              <div className='flex flex-col w-full max-w-screen-xl pl-4 pr-4 py-6'>
                <h3 className='text-4xl mt-4 mb-2'>Access Requests</h3>
                {usersAccessRequests.map((usr) => {
                  if (usr.id === user.id) return null;
                  return (
                    <div
                      key={usr.id}
                      className='flex w-full flex-1  flex-col md:flex-row  max-w-screen-xl p-4 rounded-lg items-center justify-between mb-2'
                      style={{ backgroundColor: '#d4d4d4' }}
                    >
                      <div className='flex flex-1 py-2'>
                        <h3 className='text-lg' style={{ color: '#000000' }}>
                          {usr.email}
                        </h3>
                      </div>
                      <div className='flex xl:self-end flex-row justify-center items-center py-2'>
                        {usr.access === 'pending' || usr.access === 'approved' ? (
                          <button
                            type='button'
                            className='button text-md flex self-center md:self-end'
                            style={{ backgroundColor: '#4cbb17' }}
                            onClick={() => {
                              onAcceptClick(usr);
                            }}
                          >
                            {usr.access === 'approved' ? 'Approved' : 'Accept'}
                          </button>
                        ) : null}
                        {usr.access === 'pending' || usr.access === 'rejected' ? (
                          <button
                            type='button'
                            className='button text-md flex self-center md:self-end ml-4'
                            style={{ backgroundColor: '#FA8072' }}
                            onClick={() => {
                              onRejectClick(usr);
                            }}
                          >
                            {usr.access === 'rejected' ? 'Rejected' : 'Reject'}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        )
      )}
    </div>
  );
}

export default UserProfile;
