import React from 'react';
import { Navigate } from 'react-router-dom';
import 'firebase/compat/auth';
import { useUserContext } from '../../contexts/UserProvider/UserProvider';

function SplashScreen({ approved }) {
  const { isLoggedIn, handleLogout } = useUserContext();

  if (approved) {
    return (
      <div className='flex flex-col items-center justify-center items-center w-full py-44'>
        <h1 className='text-3xl md:text-5xl text-center self-center text-black'>
          Generic LP Builder
        </h1>
        <p className='text-black font-regular text-xl py-8'>
          {approved === 'rejected'
            ? 'Your request has been rejected.'
            : 'Your request has been submitted and you will be notified once you are granted access.'}
        </p>
        <button
          type='button'
          onClick={() => {
            handleLogout();
          }}
          aria-hidden='true'
          className='flex flex-row  justify-center items-center mr-4 logoutContainer mt-4'
        >
          <h3 className='font-regular text-md mr-2 nav-title text-black'>Log out</h3>
          <img
            alt=''
            src='https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B71ac4016-3eef-414a-a04d-0096db8f0ee2%7D_output-onlinepngtools_%287%29.png'
          />
        </button>
      </div>
    );
  }
  return (
    <div className='flex flex-1 justify-center items-center container'>
      {isLoggedIn === true ? <Navigate to='/home' /> : null}
      {isLoggedIn === false ? <Navigate to='/login' /> : null}
      <img
        className='loadingImg'
        alt=''
        src='https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B9f68b913-5847-4988-aaa4-c17a1c7d6d6a%7D_loading.png'
      />
    </div>
  );
}

export default SplashScreen;
