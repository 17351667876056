function EventBuilderBody({ data }) {
  const {
    banner,
    eventAddLogo,
    eventTitle,
    eventSubTitle,
    eventDetails,
    eventAgenda,
    eventTheme,
    htmlName,
    formID,
    customFields,
    eventDescription,
    eventAddresses,
    countrySV,
    bmtID,
    eventTY,
    eventTYTitle,
    eventTYMessage,
  } = data;

  let additionalLogo = '';

  if (eventAddLogo) {
    additionalLogo = `<div class="spacer-section-SM">&nbsp;</div><img src="${eventAddLogo}" style="max-width: 200px;" class="pt-3 w-100" />`;
  }

  const colors = {
    blue1: {
      headColor: '#0096d6',
      contentColor: '#4db6e2',
      formColor: '#99d5ef',
      counterColor: '#cceaf7',
    },
    blue2: {
      headColor: '#4759f5',
      contentColor: '#7e8bf8',
      formColor: '#b6bdfb',
      counterColor: '#dadefd',
    },
    blue3: {
      headColor: '#549ef7',
      contentColor: '#87bbfa',
      formColor: '#bbd8fc',
      counterColor: '#ddecfd',
    },
    blue4: {
      headColor: '#40defa',
      contentColor: '#79e8fb',
      formColor: '#b2f2fd',
      counterColor: '#d9f8fe',
    },
    green1: {
      headColor: '#00d072',
      contentColor: '#4dde9c',
      formColor: '#99ecc7',
      counterColor: '#ccf6e3',
    },
    green2: {
      headColor: '#4ef5ab',
      contentColor: '#83f8c4',
      formColor: '#b8fbdd',
      counterColor: '#dcfdee',
    },
    green3: {
      headColor: '#83f955',
      contentColor: '#a8fb88',
      formColor: '#ccfdbb',
      counterColor: '#e6fedd',
    },
    green4: {
      headColor: '#d3fb66',
      contentColor: '#e0fc94',
      formColor: '#edfdc2',
      counterColor: '#f6fee0',
    },
    purple1: {
      headColor: '#b655f5',
      contentColor: '#cc88f8',
      formColor: '#e2bbfb',
      counterColor: '#f0ddfd',
    },
    purple2: {
      headColor: '#a89efa',
      contentColor: '#c2bbfb',
      formColor: '#dcd8fd',
      counterColor: '#eeecfe',
    },
    pink1: {
      headColor: '#ff63bb',
      contentColor: '#ff92cf',
      formColor: '#ffc1e4',
      counterColor: '#ffe0f1',
    },
    pink2: {
      headColor: '#ff84ff',
      contentColor: '#ffa9ff',
      formColor: '#ffceff',
      counterColor: '#ffe7ff',
    },
    pink3: {
      headColor: '#ff948a',
      contentColor: '#ffb4ad',
      formColor: '#ffd4d0',
      counterColor: '#ffeae8',
    },
    orange1: {
      headColor: '#ff8900',
      contentColor: '#ffac4d',
      formColor: '#ffd099',
      counterColor: '#ffe7cc',
    },
    orange2: {
      headColor: '#ffbd3b',
      contentColor: '#fed176',
      formColor: '#ffe3b1',
      counterColor: '#fff2d8',
    },
    yellow1: {
      headColor: '#ffe137',
      contentColor: '#ffea73',
      formColor: '#fff3af',
      counterColor: '#fff9d7',
    },
    yellow2: {
      headColor: '#fcf54b',
      contentColor: '#fdf881',
      formColor: '#fefbb7',
      counterColor: '#fefddb',
    },
    brown1: {
      headColor: '#856630',
      contentColor: '#aa946e',
      formColor: '#cec2ac',
      counterColor: '#e7e0d6',
    },
    darkRed1: {
      headColor: '#4b0f22',
      contentColor: '#815764',
      formColor: '#b79fa7',
      counterColor: '#dbcfd3',
    },
    darkBlue1: {
      headColor: '#212248',
      contentColor: '#64647f',
      formColor: '#a6a7b6',
      counterColor: '#d3d3da',
    },
    darkGreen1: {
      headColor: '#0c4508',
      contentColor: '#557d52',
      formColor: '#9eb59c',
      counterColor: '#cedace',
    },
    darkGrey1: {
      headColor: '#221f20',
      contentColor: '#656263',
      formColor: '#a7a5a6',
      counterColor: '#d3d2d2',
    },
  };

  function fieldsCode(type, label, name, required, options, defaultValue) {
    let fieldsCodeResult;
    let requiredField = '';
    if (required === 'Yes') requiredField = 'required="required"';
    if (type === 'input') {
      fieldsCodeResult = `<div class="col-sm-12 col-lg-4" id="container${name}">
            <div class="field-container">
                <div class="formInp-cont ${name}Input">
                    <div class="formInp">
                        <div class="float-container">
                            <label for="${name}">${label}</label>
                            <input id="${name}" name="${name}" type="text"
                                ${requiredField} value="${defaultValue}">
                            <span class="errMessage"
                                style="bottom: -16px !important;">Please
                                provide your response</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>`;
    } else if (type === 'select') {
      const itemOptions = options
        .split('\n')
        .filter((segment) => segment.trim() !== '')
        .map((segment) => {
          if (segment === defaultValue)
            return `<option value="${segment}" selected>${segment}</option>`;
          return `<option value="${segment}">${segment}</option>`;
        })
        .join('');

      fieldsCodeResult = `<div class="col-sm-12 col-lg-4" id="container${name}">
            <div class="field-container">
                <div class="formInp-cont ${name}Input">
                    <div class="formInp">
                        <div id="${name}_sel"
                            class="float-container active"
                            style=" position: relative; cursor: pointer;">
                            <label class="font-16 country-label cust-label"
                                style="z-index: 1;" for="${name}">${label}</label>
                            <div id="select-${name}-arrow"
                                style="position: absolute; right: 0; padding: 0 10px; height: 49px; pointer-events: none; transform: rotate(0deg);"
                                class="d-flex justify-content-center align-items-center cust-arrow">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    viewBox="0 0 24 24">
                                    <path
                                        d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                                </svg>
                            </div>
                            <select class="no-scroll cust-select"
                                ${requiredField} id="${name}"
                                name="${name}">
                                <option value="">- Please Select -
                                </option>
                                ${itemOptions}
                            </select>
                        </div>
                        <span class="errMessage"
                            style="bottom: -14px !important;">Please
                            provide your response</span>
                    </div>
                </div>
            </div>
        </div>`;
    } else if (type === 'textarea') {
      fieldsCodeResult = `<div class="col-sm-12 col-md-12 pb-4" id="container${name}">
            <div class="field-container">
                <textarea id="${name}" name="${name}" ${requiredField}
                    placeholder="${label}" maxlength="300"></textarea>
                <div id="the-count${name}">
                    <span id="current${name}" class="forma">0</span>
                    <span id="maximum${name}" class="forma">/ 300</span>
                </div>
            </div>
        </div>`;
    }
    return fieldsCodeResult;
  }

  const Fields = customFields
    .map((item) => {
      let result;
      const fieldCode = fieldsCode(
        item.type,
        item.label,
        item.name,
        item.required,
        item.options,
        item.defaultValue,
      );
      if (item.extraField === 'Yes') {
        const extraField = fieldsCode(
          item.extraFieldType,
          item.extraFieldLabel,
          item.extraFieldName,
          'Yes',
          item.extraFieldOptions,
          '',
        );
        const extraScript = `<script>
                $( document ).ready(function() {
                        var field${item.name} = document.getElementById('${item.name}');
                        var field${item.extraFieldName} = document.getElementById('${item.extraFieldName}');
                        var container${item.extraFieldName} = document.getElementById('container${item.extraFieldName}');

                        if(field${item.name}.value === '${item.extraFieldCondValueShow}'){
                            container${item.extraFieldName}.style.display = 'block';
                            field${item.extraFieldName}.setAttribute('required', 'required');
                            field${item.extraFieldName}.value = '';
                        }else{
                            container${item.extraFieldName}.style.display = 'none';
                            field${item.extraFieldName}.removeAttribute('required');
                            field${item.extraFieldName}.value = '';
                        }
                        
                        field${item.name}.onchange = function(){
                            if(field${item.name}.value === '${item.extraFieldCondValueShow}'){
                                container${item.extraFieldName}.style.display = 'block';
                                field${item.extraFieldName}.setAttribute('required', 'required');
                                field${item.extraFieldName}.value = '';
                            }else{
                                container${item.extraFieldName}.style.display = 'none';
                                field${item.extraFieldName}.removeAttribute('required');
                                field${item.extraFieldName}.value = '';
                            }
                        };
                    });
                </script>`;
        result = fieldCode + extraField + extraScript;
      } else {
        result = fieldCode;
      }
      return result;
    })
    .join('');

  const Addresses = eventAddresses
    .map((item) => {
      const itemText = item.text
        .split('\n')
        .filter((segment) => segment.trim() !== '')
        .map((segment) => `${segment}<br />`)
        .join('');
      const result = `<p class="size-18 txt-black no-margin"
            style="font-family:'forma-djr-text', Arial, sans-serif !important; max-width: 800px;">
            <a href="${item.link}"
                target="_blank" class="black-arrow-link py-2 forma" target="_blank">
                <span class="black-arrow-link-txt forma">${item.title}</span>

                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12"
                    class="black-arrow-right-icon">
                    <path class="arrow-right-black"
                        d="m455.465 769.707-1.847-.974 3.478-5.054-3.475-4.989 1.841-.983 4.156 5.966z"
                        transform="translate(-453.618 -757.707)"></path>
                </svg>
            </a>
            ${itemText}
        </p>
        <div class="spacer-section-SM">&nbsp;</div>`;
      return result;
    })
    .join('');

  const tyMessage = eventTYMessage
    .split('\n')
    .filter((segment) => segment.trim() !== '')
    .map((segment) => `${segment}<br /><br />`)
    .join('');

  const paragraphs = eventDescription
    .split('\n')
    .filter((segment) => segment.trim() !== '')
    .map(
      (segment) =>
        `<div class="spacer-section-SM">&nbsp;</div><p class="forma size-18 txt-black no-margin">${segment}</p>`,
    )
    .join('');

  const agenda = eventAgenda
    .split('\n')
    .filter((segment) => segment.trim() !== '')
    .map((segment) => {
      const indexOfSeparator =
        segment.indexOf('–') !== -1 ? segment.indexOf('–') : segment.indexOf('-');
      const time = segment.substring(0, indexOfSeparator).trim();
      const description = segment.substring(indexOfSeparator).trim();
      return `<p class="forma size-18 no-margin"><span class="forma b-500 size-18">${time}</span>${description}</p><div class="spacer-section-SM">&nbsp;</div>`;
    })
    .join('');

  let thankYou;

  if (eventTY === 'message') {
    thankYou = `<div id="head_2" class="row no-margin submit-after"
            style="display:none; background: ${colors[eventTheme].formColor}; padding: 15px;">
            <div class="col align-self-center">
                <div class="spacer-section-SM">&nbsp;</div>
                <h3 class="forma size-32 txt-black no-margin">${eventTYTitle}
                </h3>
            </div>
        </div>

        <div class="row no-margin submit-after"
            style="display:none; background: ${colors[eventTheme].formColor}; padding: 15px;">
            <div class="col align-self-center">
                <p class="forma size-18 txt-black no-margin">
                    ${tyMessage}
                </p>

                <div class="spacer-section-SM">&nbsp;</div>


            </div>

        </div>`;
  } else thankYou = '';

  // Constructing the code
  const code = `
    <body>
    <!-- START Privacy Banner PROD-->
    <script src="https://app.marketingcontent.ext.hp.com/e/er?s=704062726&lid=18900"></script>
    <!-- END Privacy Banner PROD-->
    <script>//UDL Analytics - GTM tag
        (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var
                f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        })(window, document,
            'script', 'dataLayer', 'GTM-MZXB4R4');
    </script>

    <script>//UDL Analytics - GTM tag
        (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var
                f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        })(window, document,
            'script', 'dataLayer', 'GTM-PDHM2PK');
    </script>

    <div id="everything" class="everything">
        <div id="content">
            <div id="header" class="header"></div>
            <div id="overlayBody">
                <div id="loader"></div>
            </div>

            <div id="body" class="body">

                <div id="overlay" style="position: fixed; top: 0px; left: 0px; z-index: 30000000; 
                        height: 100%; width: 100%; background-color: #FFFFFF; display: flex; justify-content: center;">
                    <img src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B29ced5fe-1409-4ca5-85c4-8a85d969079a%7D_Spinner-0.4s-341px.gif"
                        alt="Loading" style="margin: auto; max-width:100px;" />
                </div>
                <div style="background-color: #fff;">

                    <div class="container max-1280 no-padding">

                        <div class="row no-margin no-padding">
                            <div class="col no-padding bg-image">
                                &nbsp;
                            </div>
                        </div>

                        <div class="row no-margin"
                            style="background: ${colors[eventTheme].headColor}; padding: 15px; border-bottom: 1px solid black;">
                            <div class="col align-self-center">

                                <h1 class="forma b-500 size-38 txt-black no-margin">${eventTitle}</h1>
                                <h2 class="forma  size-30 txt-black no-margin">${eventSubTitle}
                                </h2>
                            </div>
                        </div>

                        <div class="row no-margin" style="background: ${colors[eventTheme].headColor}; padding: 15px;">
                            <div class="col align-self-center">
                                <h3 class="forma  size-24 txt-black no-margin">${eventDetails}
                                </h3>
                            </div>
                        </div>

                        <div class="row no-margin" style="background: ${colors[eventTheme].contentColor}; padding: 15px;">
                            <div class="col align-self-center">
                                ${paragraphs}
                                ${additionalLogo}
                                <div class="spacer-section-SM">&nbsp;</div>
                            </div>
                        </div>

                        ${thankYou}

                    </div>



                    <div class="container max-1280 no-padding submit-before"
                        style="background: ${colors[eventTheme].formColor}; display: block">

                        <div class="row no-margin no-padding" style=" width:100%;">
                            <div class="col-lg-12 padd-lr-15">
                                <div class="spacer-section-M"></div>
                                <!-- Form section -->

                                <div class="frm" style="padding: 0px !important; margin:auto;">
                                    <div id="load" style="padding-top:0px">

                                        <div class="form_hide_1">
                                            <form method="post" name="${htmlName}"
                                                action="https://s704062726.t.eloqua.com/e/f2" id="${formID}" novalidate="">
                                                <input value="${htmlName}"
                                                    type="hidden" name="elqFormName">
                                                <input value="704062726" type="hidden" name="elqSiteId">

                                                <!-- 
                                                <input type="hidden" class="elqCustomerGUID" name="elqCustomerGUID" value="">
                                                <input type="hidden" name="elqCookieWrite" value="0"> -->

                                                <input name="elqCampaignId" type="hidden">
                                                <input id="formID" name="formID" type="hidden" value="">
                                                <input id="jumpid" name="jumpid" type="hidden" value="">
                                                <input id="statusModule" name="status1" type="hidden" value="">
                                                <input id="interactionType" name="interactionType" type="hidden"
                                                    value="">
                                                <input id="domainFlag" name="domainFlag" type="hidden" value="">
                                                <input id="Privacy_Verbiage" name="Privacy_Verbiage" type="hidden"
                                                    value="">
                                                <input id="submittedFrom" name="SubmittedFrom" type="hidden" value="">
                                                <input id="BMTID" name="bMTActivityIDMostRecent1" type="hidden"
                                                    value="">
                                                <input id="gclid" name="gclid" type="hidden" value="">
                                                <input id="msclkid" name="msclkid" type="hidden" value="">


                                                <!--
                                                <div class="row m-0 gy-0 pr-0-desk"
                                                    style="padding-bottom:0px !important">
                                                    <div class="col-sm-12 col-md-12">
                                                        <a class="notYou blue-primary-link progressive" href=""><span
                                                                class="blue-primary-link-txt"
                                                                style="font-family: forma-djr-text, Arial, sans-serif !important;">Not
                                                                you?</span></a>
                                                    </div>
                                                </div>-->


                                                <div class="row m-0 gy-0">

                                                    <div class="col-sm-12 col-lg-4 ">
                                                        <div class="field-container">
                                                            <div class="formInp-cont firstNameInput">
                                                                <div class="formInp">
                                                                    <div class="float-container">
                                                                        <label for="firstName">First Name *</label>
                                                                        <input type="text" id="firstName"
                                                                            name="firstName" data-placeholder=""
                                                                            required="required" value=""
                                                                            autocomplete="off">
                                                                    </div>
                                                                    <span class="errMessage">Please provide your
                                                                        response</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-lg-4 ">
                                                        <div class="field-container">
                                                            <div class="formInp-cont LastNameInput">
                                                                <div class="formInp">
                                                                    <div class="float-container">
                                                                        <label for="lastName">Last Name *</label>
                                                                        <input type="text" id="lastName" name="lastName"
                                                                            data-placeholder="" required="required"
                                                                            value="" autocomplete="off">
                                                                        <span class="errMessage">Please provide your
                                                                            response</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-lg-4 ">

                                                        <div class="field-container">
                                                            <div class="formInp-cont EmailAddressInput">
                                                                <div class="formInp">
                                                                    <div class="float-container"
                                                                        style="position: static !important;">
                                                                        <label for="emailAddress">Business Email
                                                                            *</label>
                                                                        <input type="email" id="emailAddress"
                                                                            name="emailAddress" data-placeholder=""
                                                                            required="required"
                                                                            value="<span class=eloquaemail >EmailAddress</span>"
                                                                            autocomplete="off">
                                                                        <span class="errMessage">Please use a valid
                                                                            business
                                                                            email
                                                                            address</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-sm-12 col-lg-4  align-self-end">
                                                        <div class="field-container">
                                                            <div class="formInp-cont CompanyNameInput">
                                                                <div class="formInp">
                                                                    <div class="float-container floatingLabel">
                                                                        <label for="company" style="z-index: 1;">Company
                                                                            Name *</label>
                                                                        <input required="required" id="company"
                                                                            name="company" type="text" value="">
                                                                        <span class="errMessage">Please provide your
                                                                            response</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-lg-4  align-self-end">
                                                        <div class="field-container">
                                                            <div class="formInp-cont JobTitleInput">
                                                                <div class="formInp">
                                                                    <div class="float-container">
                                                                        <label for="jobTitle1">Job Title *</label>
                                                                        <input required="required" id="jobTitle1"
                                                                            name="jobTitle1" type="text" value="">
                                                                        <span class="errMessage">Please provide your
                                                                            response</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-lg-4 ">
                                                        <div class="field-container">
                                                            <div class="formInp-cont PhoneNumberInput">
                                                                <div class="formInp">
                                                                    <div class="float-container active"
                                                                        id="busPhoneChange">
                                                                        <label for="busPhone">Phone Number *</label>
                                                                        <input id="busPhone" name="busPhone" type="text"
                                                                            required="required" value="">
                                                                        <span class="errMessage" id="errBusPhone">Please
                                                                            use
                                                                            a valid
                                                                            phone number</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-lg-4 ">
                                                        <div class="field-container">
                                                            <div class="formInp-cont Address1Input">
                                                                <div class="formInp">
                                                                    <div class="float-container">
                                                                        <label for="Address1">Company Street Address
                                                                            *</label>
                                                                        <input type="text" id="Address1" name="Address1"
                                                                            data-placeholder="" required="required"
                                                                            value="" autocomplete="off">
                                                                        <span class="errMessage">Please provide your
                                                                            response</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-lg-4 " id="cityField">
                                                        <div class="field-container">
                                                            <div class="formInp-cont CityInput" id="showCity">
                                                                <div class="formInp">
                                                                    <div class="float-container">
                                                                        <label for="City">City
                                                                            *</label>
                                                                        <input id="City" name="city" type="text"
                                                                            required="required" value="">
                                                                        <span class="errMessage"
                                                                            style="bottom: -16px !important;">Please
                                                                            provide your response</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-lg-4  align-self-end">
                                                        <div class="field-container">
                                                            <div class="formInp-cont zipPostalInput">
                                                                <div class="formInp">
                                                                    <div class="float-container">
                                                                        <label for="zipPostal">ZIP Code *</label>
                                                                        <input required="required" id="zipPostal"
                                                                            name="zipPostal" type="text" value="">
                                                                        <span class="errMessage">Please provide your
                                                                            response</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-sm-12 col-lg-4 ">
                                                        <div class="field-container">
                                                            <div class="formInp-cont ContryInput">
                                                                <div class="formInp">
                                                                    <div id="country_sel" class="float-container active"
                                                                        style=" position: relative; cursor: pointer;">
                                                                        <label class="font-16 country-label"
                                                                            style="z-index: 1;" for="country">Country
                                                                            *</label>
                                                                        <div id="select-country-arrow"
                                                                            style="position: absolute; right: 0; padding: 0 10px; height: 49px; pointer-events: none; transform: rotate(0deg);"
                                                                            class="d-flex justify-content-center align-items-center">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="16" height="16"
                                                                                viewBox="0 0 24 24">
                                                                                <path
                                                                                    d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                                                                            </svg>
                                                                        </div>
                                                                        <select class="no-scroll" required="required"
                                                                            id="country" name="country"
                                                                            data-value=""><span
                                                                                style="color: #BF2026;">*</span>
                                                                            <option value="">- Please Select -</option>
                                                                            <span elqid="7099" elqtype="Section" class="remove-absolute" style="display: block"></span>
                                                                            
                                                                        </select>
                                                                    </div>
                                                                    <span class="errMessage"
                                                                        style="bottom: -14px !important;">Please
                                                                        provide your response</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <!-- SHOW STATE US / CA -->
                                                    <style>
                                                        .no-display-state {
                                                            display: none;
                                                        }
                                                    </style>
                                                    <div class="col-sm-12 col-lg-4 " id="stateField">
                                                        <div class="field-container">
                                                            <div class="formInp-cont StateInput" style="display: none;"
                                                                id="showState">
                                                                <div class="formInp">
                                                                    <div id="stateProv_sel"
                                                                        class="float-container active focused"
                                                                        style=" position: relative; cursor: pointer;">
                                                                        <label id="stateLabel"
                                                                            class="font-16 country-label"
                                                                            style="z-index: 1;" for="stateProv">State:
                                                                            *</label>
                                                                        <div id="select-state-arrow"
                                                                            style="position: absolute; right: 0; padding: 0 10px; height: 49px; pointer-events: none; transform: rotate(0deg);"
                                                                            class="d-flex justify-content-center align-items-center">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                width="16" height="16"
                                                                                viewBox="0 0 24 24">
                                                                                <path
                                                                                    d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                                                                            </svg>
                                                                        </div>

                                                                        <select class="no-scroll" id="stateProv"
                                                                            name="stateProv" data-value=""
                                                                            placeholder="State/Province">
                                                                            <option value="">-
                                                                                Please
                                                                                Select -
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="AL">
                                                                                Alabama
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="AK">
                                                                                Alaska
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="AZ">
                                                                                Arizona
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="AR">
                                                                                Arkansas
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="CA">
                                                                                California
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="CO">
                                                                                Colorado
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="CT">
                                                                                Connecticut
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="DE">
                                                                                Delaware
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="D.C.">
                                                                                District of
                                                                                Columbia
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="FL">
                                                                                Florida
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="GA">
                                                                                Georgia
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="HI">
                                                                                Hawaii
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="ID">
                                                                                Idaho
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="IL">
                                                                                Illinois
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="IN">
                                                                                Indiana
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="IA">
                                                                                Iowa
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="KS">
                                                                                Kansas
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="KY">
                                                                                Kentucky
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="LA">
                                                                                Louisiana
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="ME">
                                                                                Maine
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="MD">
                                                                                Maryland
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="MA">
                                                                                Massachusetts
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="MI">
                                                                                Michigan
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="MN">
                                                                                Minnesota
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="MS">
                                                                                Mississippi
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="MO">
                                                                                Missouri
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="MT">
                                                                                Montana
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="NE">
                                                                                Nebraska
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="NV">
                                                                                Nevada
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="NH">
                                                                                New
                                                                                Hampshire
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="NJ">
                                                                                New Jersey
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="NM">
                                                                                New Mexico
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="NY">
                                                                                New York
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="NC">
                                                                                North
                                                                                Carolina
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="ND">
                                                                                North Dakota
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="OH">
                                                                                Ohio
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="OK">
                                                                                Oklahoma
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="OR">
                                                                                Oregon
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="PA">
                                                                                Pennsylvania
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="PR">
                                                                                Puerto Rico
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="RI">
                                                                                Rhode Island
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="SC">
                                                                                South
                                                                                Carolina
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="SD">
                                                                                South Dakota
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="TN">
                                                                                Tennessee
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="TX">
                                                                                Texas
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="UT">
                                                                                Utah
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="VT">
                                                                                Vermont
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="VA">
                                                                                Virginia
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="WA">
                                                                                Washington
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="WV">
                                                                                West
                                                                                Virginia
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="WI">
                                                                                Wisconsin
                                                                            </option>
                                                                            <option class="no-display-state usState"
                                                                                value="WY">
                                                                                Wyoming
                                                                            </option>
                                                                            <!-- CA STATES -->
                                                                            <option class="no-display-state caState"
                                                                                value="AB">
                                                                                Alberta
                                                                            </option>
                                                                            <option class="no-display-state caState"
                                                                                value="BC">
                                                                                British
                                                                                Columbia
                                                                            </option>
                                                                            <option class="no-display-state caState"
                                                                                value="MB">
                                                                                Manitoba
                                                                            </option>
                                                                            <option class="no-display-state caState"
                                                                                value="NB">
                                                                                New
                                                                                Brunswick
                                                                            </option>
                                                                            <option class="no-display-state caState"
                                                                                value="NL">
                                                                                Newfoundland
                                                                                and Labrador
                                                                            </option>
                                                                            <option class="no-display-state caState"
                                                                                value="NS">
                                                                                Nova Scotia
                                                                            </option>
                                                                            <option class="no-display-state caState"
                                                                                value="NT">
                                                                                Northwest
                                                                                Territories
                                                                            </option>
                                                                            <option class="no-display-state caState"
                                                                                value="NU">
                                                                                Nunavut
                                                                            </option>
                                                                            <option class="no-display-state caState"
                                                                                value="ON">
                                                                                Ontario
                                                                            </option>
                                                                            <option class="no-display-state caState"
                                                                                value="PE">
                                                                                Prince
                                                                                Edward
                                                                                Island
                                                                            </option>
                                                                            <option class="no-display-state caState"
                                                                                value="QC">
                                                                                Quebec
                                                                            </option>
                                                                            <option class="no-display-state caState"
                                                                                value="SK">
                                                                                Saskatchewan
                                                                            </option>
                                                                            <option class="no-display-state caState"
                                                                                value="YT">
                                                                                Yukon
                                                                                Territories
                                                                            </option>
                                                                            <!-- END OF CA STATES -->
                                                                            <!-- IN STATES -->
                                                                            <option class="no-display-state inState" 
                                                                                value="AN">
                                                                                Andaman and Nicobar Islands
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="AP">
                                                                                Andhra Pradesh
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="AR">
                                                                                Arunachal Pradesh
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="AS">
                                                                                Assam
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="BR">
                                                                                Bihar
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="CH">
                                                                                Chandigarh
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="CG">
                                                                                Chhattisgarh
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="DN">
                                                                                Dadra and Nagar Haveli and Daman and Diu
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="DL">
                                                                                Delhi
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="GA">
                                                                                Goa
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="GJ">
                                                                                Gujarat
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="HR">
                                                                                Haryana
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="HP">
                                                                                Himachal Pradesh
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="JK">
                                                                                Jammu and Kashmir
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="JH">
                                                                                Jharkhand
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="KA">
                                                                                Karnataka
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="KL">
                                                                                Kerala
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="LA">
                                                                                Ladakh
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="LD">
                                                                                Lakshadweep
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="MP">
                                                                                Madhya Pradesh
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="MH">
                                                                                Maharashtra
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="MN">
                                                                                Manipur
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="ML">
                                                                                Meghalaya
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="MZ">
                                                                                Mizoram
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="NL">
                                                                                Nagaland
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="OR">
                                                                                Odisha
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="PY">
                                                                                Puducherry
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="PB">
                                                                                Punjab
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="RJ">
                                                                                Rajasthan
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="SK">
                                                                                Sikkim
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="TN">
                                                                                Tamil Nadu
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="TG">
                                                                                Telangana
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="TR">
                                                                                Tripura
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="UP">
                                                                                Uttar Pradesh
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="UK">
                                                                                Uttarakhand
                                                                            </option>
                                                                            <option class="no-display-state inState" 
                                                                                value="WB">
                                                                                West Bengal
                                                                            </option>
                                                                            <!-- END OF IN STATES -->
                                                                        </select>
                                                                    </div>
                                                                    <span class="errMessage"
                                                                        style="bottom: -14px !important;">Please
                                                                        provide your response</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- END OF SHOW STATE US / CA -->

                                                    ${Fields}


                                                    <div class="col-lg-12">
                                                        <div class="row no-padding no-margin">


                                                            <div class="col-sm-12 col-md-12 no-padding">
                                                                <div class="row no-margin no-padding">

                                                                    <div class="col-12 no-padding">
                                                                        <div class="row m-0">
                                                                            <div class="col-sm-12 col-lg-12 pb-1"
                                                                                style="padding-left:0;">
                                                                                <h1
                                                                                    class="forma size-16  py-3 no-margin">
                                                                                    HP respects your privacy. Visit <a
                                                                                        href="https://www8.hp.com/${countrySV}/en/privacy/privacy.html?bmtid=${bmtID}"
                                                                                        class="forma size-16 in-text-link"
                                                                                        target="_blank">
                                                                                        HP's Privacy Statement</a> to
                                                                                    learn how HP
                                                                                    collects and uses your
                                                                                    personal data.<br>
                                                                                </h1>

                                                                            </div>

                                                                        </div>
                                                                    </div>


                                                                    <div class="col-lg-12 col-sm-12 no-padding">
                                                                        <label class="privacyVerb"
                                                                            for="masterEmailOptin1"><input
                                                                                id="masterEmailOptin1"
                                                                                name="masterEmailOptin1" type="checkbox"
                                                                                value="925250000" />HP may contact me with personalized offers, support updates, and event news by e-mail.</label>
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 no-padding">
                                                                        <label class="privacyVerb"
                                                                            for="masterPhoneOptin1"><input
                                                                                id="masterPhoneOptin1"
                                                                                name="masterPhoneOptin1" type="checkbox"
                                                                                value="925250000">HP may contact me with personalized offers, support updates, and event news by phone.</label>
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 no-padding">
                                                                        <label class="privacyVerb pb-4 mb-2"
                                                                            for="masterMailOptin1"><input
                                                                                id="masterMailOptin1"
                                                                                name="masterMailOptin1" type="checkbox"
                                                                                value="925250000" />HP may contact me with personalized offers, support updates, and event news by mail.</label>
                                                                    </div>
                                                                    <div class="col-lg-12 no-padding"
                                                                        style="display: flex; justify-content: left; max-width: 150px;">
                                                                        <input type="submit" name="sbm" id="submitBtn"
                                                                            value="Register"
                                                                            class="outlineW-primary-btn-large button submit-button font-16"
                                                                            style=" width: 150px !important; border-radius: 0 !important; font-family: forma-djr-micro, Arial, sans-serif !important; font-weight: 500;">
                                                                        <svg id="submitLoader"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                            style="background: none; display: none; shape-rendering: auto;"
                                                                            width="40px" height="40px"
                                                                            viewBox="0 0 100 100"
                                                                            preserveAspectRatio="xMidYMid">
                                                                            <g transform="rotate(0 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.7724137931034483s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(12.413793103448276 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.7448275862068966s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(24.82758620689655 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.7172413793103448s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(37.241379310344826 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.6896551724137931s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(49.6551724137931 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.6620689655172414s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(62.06896551724138 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.6344827586206897s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(74.48275862068965 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.6068965517241379s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(86.89655172413794 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.5793103448275863s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(99.3103448275862 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.5517241379310345s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(111.72413793103448 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.5241379310344828s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(124.13793103448276 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.496551724137931s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(136.55172413793105 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.4689655172413793s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(148.9655172413793 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.4413793103448276s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(161.3793103448276 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.41379310344827586s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(173.79310344827587 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.38620689655172413s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(186.20689655172413 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.3586206896551724s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(198.6206896551724 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.3310344827586207s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(211.0344827586207 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.30344827586206896s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(223.44827586206895 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.27586206896551724s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(235.86206896551724 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.2482758620689655s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(248.27586206896552 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.2206896551724138s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(260.6896551724138 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.19310344827586207s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(273.1034482758621 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.16551724137931034s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(285.51724137931035 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.13793103448275862s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(297.9310344827586 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.1103448275862069s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(310.3448275862069 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.08275862068965517s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(322.7586206896552 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.05517241379310345s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(335.17241379310343 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s"
                                                                                        begin="-0.027586206896551724s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                            <g
                                                                                transform="rotate(347.58620689655174 50 50)">
                                                                                <rect x="40.5" y="18.5"
                                                                                    rx="9.120000000000001" ry="7.5"
                                                                                    width="19" height="15"
                                                                                    fill="#1d0e0b">
                                                                                    <animate attributeName="opacity"
                                                                                        values="1;0" keyTimes="0;1"
                                                                                        dur="0.8s" begin="0s"
                                                                                        repeatCount="indefinite" />
                                                                                </rect>
                                                                            </g>
                                                                        </svg>

                                                                        <div class="spacer-section-MD"></div>
                                                                    </div>

                                                                    <!-- End of Mail opt-in -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <!-- End of form section -->
                            </div>
                        </div>


                    </div>

                    <style>
                        @media screen and (max-width: 380px) {
                            .d-block-max-380 {
                                display: block !important;
                            }
                        }
                    </style>

                    <!-- Countdown section -->
                    <div class="container max-1280 no-padding">
                        <div class="row no-margin py-3" style="background: ${colors[eventTheme].counterColor}; padding: 10px;" id="output">
                            <div class="col-lg-3 align-self-center py-3" style="max-width: 250px !important;">
                                <h2 class="forma b-300 size-24 txt-black no-margin">Your event starts in:</h2>
                            </div>
                            <div class="col-lg-9 d-block-max-380 align-self-center" style="display:flex;">
                                <h2 id="days"
                                    class="forma b-300 size-28 txt-black no-margin pe-3 pe-lg-4 py-3 text-center"></h2>
                                <h2 id="hours"
                                    class="forma b-300 size-28 txt-black no-margin pe-3 pe-lg-4 py-3 text-center"></h2>
                                <h2 id="minutes"
                                    class="forma b-300 size-28 txt-black no-margin pe-3 pe-lg-4 py-3 text-center"></h2>
                                <h2 id="seconds"
                                    class="forma b-300 size-28 txt-black no-margin pe-3 pe-lg-4 py-3 text-center"></h2>
                            </div>
                        </div>
                    </div>
                    <!-- end Countdown section -->



                    <!-- title section -->
                    <div class="container max-1280 no-padding">
                        <div class="row no-margin"
                            style="background: white; padding: 10px; border-bottom: 1px solid black;">
                            <div class="col align-self-center">
                                <h2 class="forma b-500 size-38 txt-black no-margin">EVENT AGENDA </h2>
                            </div>
                        </div>
                    </div>
                    <!-- end title section -->


                    <!-- content -->
                    <div class="container max-1280 no-padding">
                        <div class="row no-margin " style="padding: 15px;">
                            <div class="col">
                                <div class="spacer-section-SM">&nbsp;</div>
                            </div>

                            <div class="w-100"></div>

                            <div class="col-lg-8 col-md-8 col-sm-12 ">
                                ${agenda}

                                ${Addresses}
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-12 align-self-center">
                                <img src="${banner}"
                                    style="width: 100%; display: block;">
                            </div>

                            <div class="w-100"></div>

                            <div class="col">
                                <div class="spacer-section-M">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                    <!-- ###############
                    #### end content -->

                </div>



                <!-- End of page content -->
                <!-- Important: DO NOT ADD CONTENT BELOW footer and birdseed -->
                <div id="footer" class="footer"></div>
                <div id="birdseed" class="birdseed"></div>
            </div>
        </div>
    </div>`;

  return code;
}

export default EventBuilderBody;
