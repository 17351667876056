import './App.css';
import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { GeneralProvider } from './contexts/GeneralContext';
import { useUserContext } from './contexts/UserProvider/UserProvider';
import HomeScreen from './screens/HomeScreen/HomeScreen';
import Build from './screens/Build/Build';
import NavBar from './components/NavBar/NavBar';
import SplashScreen from './screens/SplashScreen/SplashScreen';
import LoginScreen from './screens/LoginScreen/LoginScreen';
import RegisterScreen from './screens/RegisterScreen/RegisterScreen';
import ForgotPassword from './screens/ForgotPassword/ForgotPassword';
import NotFoundRedirect from './screens/NotFoundRedirect/NotFoundRedirect';
import EventBuilder from './builders/EventBuilder/EventBuilder';
import UserProfile from './screens/UserProfile/UserProfile';

export default function AppMain() {
  const { isLoggedIn, user } = useUserContext();

  const getDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const day = today.getDate();
    const paddedDay = day < 10 ? `0${day}` : day;
    const paddedMonth = month < 10 ? `0${month}` : month;
    const date = `${year}-${paddedMonth}-${paddedDay}`;
    return date;
  };

  React.useEffect(() => {
    if (isLoggedIn === true) {
      firebase
        .database()
        .ref(`/users/${firebase.auth().currentUser.uid}/logins/${getDate()}`)
        .set(true);
    }
  }, [isLoggedIn]);

  if (isLoggedIn === null) {
    return <SplashScreen />;
  }
  if (
    isLoggedIn === true &&
    user.id !== 'loading' &&
    (user.access === 'pending' || user.access === 'rejected')
  ) {
    return <SplashScreen approved={user.access} />;
  }

  return (
    <GeneralProvider isLoggedIn={isLoggedIn}>
      <HashRouter>
        {isLoggedIn === true ? <NavBar /> : null}
        <Routes>
          <Route path='/event' element={<EventBuilder />} />
          <Route exact path='/build/' element={<Build />} />
          <Route exact path='/build/:pageId' element={<Build />} />
          <Route path='/home' element={<HomeScreen />} />
          <Route path='/user-profile' element={<UserProfile />} />
          <Route path='/login' element={<LoginScreen />} />
          <Route path='/register' element={<RegisterScreen />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/' element={<SplashScreen />} />
          <Route path='*' element={<NotFoundRedirect />} />
        </Routes>
      </HashRouter>
    </GeneralProvider>
  );
}
