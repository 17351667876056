import './Build.css';
import React from 'react';
import grapesjs from 'grapesjs';
import gjsPresetWebpage from 'grapesjs-preset-webpage';
import gjsBlocksBasic from 'grapesjs-blocks-basic';
import { Navigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserProvider/UserProvider';

function Build() {
  const { user, isLoggedIn } = useUserContext();
  const [editor, setEditor] = React.useState(null);
  const { pageId } = useParams();

  React.useEffect(() => {
    if (isLoggedIn && user.access === 'approved' && user.isAdmin) {
      const editorConfig = grapesjs.init({
        container: '#editor',
        plugins: [gjsPresetWebpage, gjsBlocksBasic],
        pluginsOpts: {
          [gjsPresetWebpage]: {},
        },
      });
      setEditor(editorConfig);
    }
  }, [isLoggedIn, user]);

  return (
    <div className='App'>
      {isLoggedIn === false ? (
        <Navigate to='/login' />
      ) : (
        user.access === 'approved' && user.isAdmin === true && <div id='editor' />
      )}
    </div>
  );
}

export default Build;
