import './EventBuilder.css';
import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { Navigate, useLocation } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserProvider/UserProvider';
import EventBuilderHeader from '../../templates/EventBuilderCode/EventBuilderHeader';
import EventBuilderBody from '../../templates/EventBuilderCode/EventBuilderBody';
import EventBuilderFooter from '../../templates/EventBuilderCode/EventBuilderFooter';
import EventBuilderMail from '../../templates/EventBuilderCode/EventBuilderMail';

function EventBuilder(props) {
  // user state
  const { user, isLoggedIn } = useUserContext();
  const [eventTYP, setEventTYP] = React.useState('');
  // event from history state
  const location = useLocation();
  const dataFromComponent = location.state ? location.state.data : null;

  // addresses state
  const [addressCount, setAddressCount] = React.useState(0);
  const [addresses, setAddresses] = React.useState([
    { title: '', text: '', link: '', id: `address${addressCount}` },
  ]);

  // page fields state
  const [fieldCount, setFieldCount] = React.useState(0);
  const [fields, setFields] = React.useState([]);
  const [showOptions, setShowOptions] = React.useState([]);
  const [showExtraField, setShowExtraField] = React.useState([]);
  const [showExtraFieldOptions, setShowExtraFieldOptions] = React.useState([]);

  // download function
  const generateAndDownloadHTMLFile = (text, type) => {
    const blob = new Blob([text], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${type} - ${formData.vanity}.html`;
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
  };

  // event builder form
  const [formData, setFormData] = React.useState({
    bmtID: '',
    bGroup: '',
    vanity: '',
    countrySV: '',
    countryLV: '',
    languageSV: '',
    campaignName: '',
    topic: '',
    htmlName: '',
    formID: '',
    banner: '',
    eventTitle: '',
    eventSubTitle: '',
    eventDetails: '',
    eventDescription: '',
    eventAddLogo: '',
    eventDate: '',
    eventTY: '',
    eventTYURL: '',
    eventTYTitle: '',
    eventTYMessage: '',
    mailTitle: '',
    mailSubTitle: '',
    mailContent: '',
    mailGoogleCalendar: '',
    mailOutlookCalendar: '',
    eventAddresses: [],
    customFields: [],
    eventTheme: '',
  });

  const [selectedTheme, setSelectedTheme] = React.useState('');
  React.useEffect(() => {
    if (dataFromComponent) {
      setFormData(dataFromComponent);
      setSelectedTheme(dataFromComponent.eventTheme);
      setEventTYP(dataFromComponent.eventTY);
      if (dataFromComponent.eventAddresses) {
        setAddressCount(dataFromComponent.eventAddresses.length);
        setAddresses(dataFromComponent.eventAddresses);
      }
      if (dataFromComponent.customFields) {
        setFieldCount(dataFromComponent.customFields.length);
        setFields(dataFromComponent.customFields);
        const newShowOptions = [...showOptions];
        const newShowExtraField = [...showExtraField];
        const newShowExtraFieldOptions = [...showExtraFieldOptions];
        dataFromComponent.customFields.forEach((obj, index) => {
          if (obj.type === 'select') {
            newShowOptions[index] = true;
          } else {
            newShowOptions[index] = false;
          }

          if (obj.extraField === 'Yes') {
            newShowExtraField[index] = true;
          } else {
            newShowExtraField[index] = false;
          }

          if (obj.extraFieldType === 'select') {
            newShowExtraFieldOptions[index] = true;
          } else {
            newShowExtraFieldOptions[index] = false;
          }

          setShowExtraFieldOptions(newShowExtraFieldOptions);
          setShowOptions(newShowOptions);
          setShowExtraField(newShowExtraField);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFromComponent]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'eventTY') {
      if (value === 'message') {
        setEventTYP('message');
        setFormData((prevData) => ({
          ...prevData,
          eventTYURL: '',
          [name]: value,
        }));
      } else if (value === 'page') {
        setEventTYP('page');
        setFormData((prevData) => ({
          ...prevData,
          eventTYMessage: '',
          eventTYTitle: '',
          [name]: value,
        }));
      } else {
        setEventTYP('');
        setFormData((prevData) => ({
          ...prevData,
          eventTYMessage: '',
          eventTYTitle: '',
          eventTYURL: '',
          [name]: value,
        }));
      }
    } else {
      if (name === 'eventTheme') setSelectedTheme(value);
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // addresses handlers
  const handleChangeAddress = (index, e) => {
    const { name, value } = e.target;
    const newAddresses = [...addresses];
    newAddresses[index][name] = value;
    setAddresses(newAddresses);
  };

  const handleAddAddress = () => {
    setAddressCount(addressCount + 1);
    setAddresses([
      ...addresses,
      { title: '', text: '', link: '', id: `address${addressCount + 1}` },
    ]);
  };

  const handleRemoveAddress = () => {
    if (addressCount >= 1) {
      setAddressCount(addressCount - 1);
      setAddresses(addresses.slice(0, -1));
    }
  };

  React.useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      eventAddresses: addresses,
    }));
  }, [addresses]);

  // page fields handlers
  const handleChangeField = (index, e) => {
    const { name, value } = e.target;
    const newFields = [...fields];
    const newShowOptions = [...showOptions];
    const newShowExtraField = [...showExtraField];
    const newShowExtraFieldOptions = [...showExtraFieldOptions];
    if (name === 'type') {
      if (value === 'select') {
        newShowOptions[index] = true;
      } else {
        newShowOptions[index] = false;
        newFields[index].options = '';
      }
    }

    if (name === 'extraFieldType') {
      if (value === 'select') {
        newShowExtraFieldOptions[index] = true;
      } else {
        newShowExtraFieldOptions[index] = false;
        newFields[index].extraFieldOptions = '';
      }
    }

    if (name === 'extraField') {
      if (value === 'Yes') {
        newShowExtraField[index] = true;
      } else {
        newShowExtraField[index] = false;
        newShowExtraFieldOptions[index] = false;
        newFields[index].extraFieldLabel = '';
        newFields[index].extraFieldType = '';
        newFields[index].extraFieldName = '';
        newFields[index].extraFieldCondValueShow = '';
        newFields[index].extraFieldOptions = '';
      }
    }

    setShowExtraFieldOptions(newShowExtraFieldOptions);
    setShowOptions(newShowOptions);
    setShowExtraField(newShowExtraField);
    newFields[index][name] = value;
    setFields(newFields);
  };

  const handleAddField = () => {
    setFieldCount(fieldCount + 1);
    setFields([
      ...fields,
      {
        type: '',
        label: '',
        name: '',
        required: '',
        options: '',
        defaultValue: '',
        extraField: '',
        extraFieldType: '',
        extraFieldLabel: '',
        extraFieldName: '',
        extraFieldOptions: '',
        extraFieldCondValueShow: '',
        id: `field${fieldCount}`,
      },
    ]);
  };

  const handleRemoveField = () => {
    if (fieldCount > 0) {
      setFieldCount(fieldCount - 1);
      setFields(fields.slice(0, -1));
      setShowOptions(showOptions.slice(0, -1));
      setShowExtraField(showExtraField.slice(0, -1));
    }
  };

  React.useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      customFields: fields,
    }));
  }, [fields]);

  // sent form data to generate html
  const getDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const day = today.getDate();
    const paddedDay = day < 10 ? `0${day}` : day;
    const paddedMonth = month < 10 ? `0${month}` : month;
    const date = `${year}-${paddedMonth}-${paddedDay}`;
    return date;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const header = EventBuilderHeader({ data: formData });
    const body = EventBuilderBody({ data: formData });
    const footer = EventBuilderFooter({ data: formData });
    const page = header + body + footer;
    const mail = EventBuilderMail({ data: formData });
    generateAndDownloadHTMLFile(page, 'event');
    generateAndDownloadHTMLFile(mail, 'mail');

    firebase
      .database()
      .ref(
        `/users/${firebase.auth().currentUser.uid}/lpsCreated/event/${getDate()}/${
          formData.bmtID
        }+${formData.vanity}`,
      )
      .set(formData);
  };

  return (
    <div className='App'>
      {isLoggedIn === false ? (
        <Navigate to='/login' />
      ) : (
        user.access === 'approved' && (
          <div className='App-header flex flex-row w-full justify-center'>
            <div className='flex flex-col max-w-screen-xl w-full px-4'>
              <div className='flex flex-row w-full justify-center'>
                <h1 className='text-5xl max-w-3xl text-white pt-16'>
                  Default Event Builder - VisID
                </h1>
              </div>
              <div className='flex flex-row w-full justify-center mb-20'>
                <p className='text-2xl'>Start creating your event</p>
              </div>
              <form className='flex flex-col items-center' onSubmit={handleSubmit}>
                <div className='flex flex-row w-full justify-left mb-4'>
                  <p className='text-3xl pb-3'>Default Configuration</p>
                </div>
                <div className='grid grid-cols-1 xl:grid-cols-3 gap-4 w-full justify-center'>
                  <div className='inputContainer'>
                    <label htmlFor='bmtID' className='grid'>
                      BMT ID
                      <input
                        id='bmtID'
                        name='bmtID'
                        required='required'
                        onChange={handleChange}
                        placeholder='ex: US-FY23-PS12344-78'
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.bmtID}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='bGroup' className='grid'>
                      Business Group
                      <select
                        id='bGroup'
                        name='bGroup'
                        required='required'
                        onChange={handleChange}
                        placeholder='ex: PS, PRINT, 3DP, WSS, POLY, GSB'
                        className='mt-2 mb-4 rounded-lg'
                        value={formData.bGroup}
                      >
                        <option value=''> - Please Select -</option>
                        <option value='PS'>PS</option>
                        <option value='PRINT'>PRINT</option>
                        <option value='3DP'>3DP</option>
                        <option value='WSS'>WSS</option>
                        <option value='POLY'>POLY</option>
                        <option value='GSB'>GSB</option>
                      </select>
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='vanity' className='grid'>
                      Vanity URL
                      <input
                        id='vanity'
                        name='vanity'
                        required='required'
                        onChange={handleChange}
                        placeholder='basketball-march-23'
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.vanity}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='countrySV' className='grid'>
                      Country Short Value
                      <input
                        id='countrySV'
                        name='countrySV'
                        required='required'
                        onChange={handleChange}
                        placeholder='ex: us, au, vn'
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.countrySV}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='countryLV' className='grid'>
                      Country Long Value
                      <input
                        id='countryLV'
                        name='countryLV'
                        required='required'
                        onChange={handleChange}
                        placeholder='ex: United States, Australia, Vietnam'
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.countryLV}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='languageSV' className='grid'>
                      Language Short Value
                      <input
                        id='languageSV'
                        name='languageSV'
                        required='required'
                        onChange={handleChange}
                        placeholder='ex: en, fr, es'
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.languageSV}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='campaignName' className='grid'>
                      Campaign Name
                      <input
                        id='campaignName'
                        name='campaignName'
                        required='campaignName'
                        onChange={handleChange}
                        placeholder='ex: HP US FY24 Events'
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.campaignName}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='topic' className='grid'>
                      Topic
                      <input
                        id='topic'
                        name='topic'
                        required='topic'
                        onChange={handleChange}
                        placeholder='ex: HP US FY24 Basketball March 23 Event'
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.topic}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='htmlName' className='grid'>
                      Form HTML Name
                      <input
                        id='htmlName'
                        name='htmlName'
                        required='htmlName'
                        onChange={handleChange}
                        placeholder='FMVFUS-FY24-PS18147-57bigeast-basketball-march14'
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.htmlName}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='formID' className='grid'>
                      Form ID
                      <input
                        id='formID'
                        name='formID'
                        required='formID'
                        onChange={handleChange}
                        placeholder='5895'
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.formID}
                      />
                    </label>
                  </div>
                </div>
                <div className='flex flex-row w-full justify-left mt-20 mb-4'>
                  <p className='text-3xl pb-3'>Content Configuration</p>
                </div>

                <div className='grid grid-cols-1 mb-5 gap-4 w-full justify-center'>
                  <div className='inputContainer'>
                    <h1 className='font-regular text-base mb-1 text-center'>Event Color Theme</h1>
                    <fieldset className='flex w-full'>
                      <div className='grid gap-1 grid-cols-7 xl:grid-cols-11 mr-1'>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio1'
                            name='eventTheme'
                            value='blue1'
                            checked={selectedTheme === 'blue1'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio1'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fblue1.jpg?alt=media&token=fe0211dd-1cd8-4804-9293-833d37f71c98'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio2'
                            name='eventTheme'
                            value='blue2'
                            checked={selectedTheme === 'blue2'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio2'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fblue2.jpg?alt=media&token=a6d86fa5-fa81-4561-b473-d2803ffcc986'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio3'
                            name='eventTheme'
                            value='blue3'
                            checked={selectedTheme === 'blue3'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio3'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fblue3.jpg?alt=media&token=68a61280-5811-4dfe-872f-6a50cf5142fd'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio4'
                            name='eventTheme'
                            value='blue4'
                            checked={selectedTheme === 'blue4'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio4'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fblue4.jpg?alt=media&token=ad07ee82-7ce0-482c-b171-68a5de6cc34b'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio5'
                            name='eventTheme'
                            value='green1'
                            checked={selectedTheme === 'green1'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio5'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fgreen1.jpg?alt=media&token=813f1bc4-e86f-4e07-bd8c-9ee5ff697506'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio6'
                            name='eventTheme'
                            value='green2'
                            checked={selectedTheme === 'green2'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio6'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fgreen2.jpg?alt=media&token=6837acfd-319b-480b-a8e2-af2b73f4a4c1'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio7'
                            name='eventTheme'
                            value='green3'
                            checked={selectedTheme === 'green3'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio7'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fgreen3.jpg?alt=media&token=6190be47-c29c-4d39-848f-2536440eb19a'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio8'
                            name='eventTheme'
                            value='green4'
                            checked={selectedTheme === 'green4'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio8'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fgreen4.jpg?alt=media&token=95c6ec83-4060-4b61-ab4f-c514a6dc4817'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio9'
                            name='eventTheme'
                            value='purple1'
                            checked={selectedTheme === 'purple1'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio9'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fpurple1.jpg?alt=media&token=dc21208f-bc16-4205-9946-8b8c5dfe014c'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio10'
                            name='eventTheme'
                            value='purple2'
                            checked={selectedTheme === 'purple2'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio10'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fpurple2.jpg?alt=media&token=c56b7244-7ec8-429d-ac46-4d806349dc21'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio11'
                            name='eventTheme'
                            value='pink1'
                            checked={selectedTheme === 'pink1'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio11'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fpink1.jpg?alt=media&token=4a9c6245-760b-4264-ad15-271fcb7ca9af'
                            />
                          </label>
                        </div>
                      </div>
                      <div className='grid gap-1 grid-cols-7 xl:grid-cols-11'>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio12'
                            name='eventTheme'
                            value='pink2'
                            checked={selectedTheme === 'pink2'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio12'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fpink2.jpg?alt=media&token=c9c1b490-3b33-422d-ac81-eec59ad92e12'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio13'
                            name='eventTheme'
                            value='pink3'
                            checked={selectedTheme === 'pink3'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio13'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fpink3.jpg?alt=media&token=c0ba5cb3-187e-4f07-886f-5a92d62931d3'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio14'
                            name='eventTheme'
                            value='orange1'
                            checked={selectedTheme === 'orange1'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio14'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Forange1.jpg?alt=media&token=29aea025-9204-48b4-915a-0cabda776589'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio15'
                            name='eventTheme'
                            value='orange2'
                            checked={selectedTheme === 'orange2'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio15'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Forange2.jpg?alt=media&token=6ae5de22-7e14-42a5-9225-1f58727e177f'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio16'
                            name='eventTheme'
                            value='yellow1'
                            checked={selectedTheme === 'yellow1'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio16'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fyellow1.jpg?alt=media&token=112e1ff4-38aa-4316-bea5-6821dfb4ef11'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio17'
                            name='eventTheme'
                            value='yellow2'
                            checked={selectedTheme === 'yellow2'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio17'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fyellow2.jpg?alt=media&token=c9df401f-7950-4b70-814e-5b0866d86a7c'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio18'
                            name='eventTheme'
                            value='brown1'
                            checked={selectedTheme === 'brown1'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio18'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2Fbrown1.jpg?alt=media&token=66b90036-5e20-4d49-82dc-f6b24d6a88ed'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio19'
                            name='eventTheme'
                            value='darkRed1'
                            checked={selectedTheme === 'darkRed1'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio19'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2FdarkRed1.jpg?alt=media&token=89c91690-c1d7-493a-ac31-11deaee2e781'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio20'
                            name='eventTheme'
                            value='darkBlue1'
                            checked={selectedTheme === 'darkBlue1'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio20'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2FdarkBlue1.jpg?alt=media&token=a117dafe-9af8-449b-b1c9-5725cbc797e4'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio21'
                            name='eventTheme'
                            value='darkGreen1'
                            checked={selectedTheme === 'darkGreen1'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio21'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2FdarkGreen1.jpg?alt=media&token=2b6e58d4-01c8-4f3c-becd-45baa825e610'
                            />
                          </label>
                        </div>
                        <div className='flex flex-col items-center inputTheme'>
                          <input
                            type='radio'
                            className='my-3'
                            id='radio22'
                            name='eventTheme'
                            value='darkGrey1'
                            checked={selectedTheme === 'darkGrey1'}
                            required='required'
                            onChange={handleChange}
                          />
                          <label className='mb-3' htmlFor='radio22'>
                            {' '}
                            <img
                              alt=''
                              className='themeColor'
                              src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Event_Tools%2FdarkGrey1.jpg?alt=media&token=3315f7d4-34a6-40ad-a311-eb23ea89206f'
                            />
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div className='grid grid-cols-1 xl:grid-cols-2 gap-4 w-full justify-center'>
                  <div className='inputContainer'>
                    <label htmlFor='banner' className='grid'>
                      Banner Image URL
                      <input
                        id='banner'
                        name='banner'
                        required='required'
                        onChange={handleChange}
                        placeholder='Upload image in Eloqua and insert link here...'
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.banner}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='eventDetails' className='grid'>
                      Event Additional Logo URL
                      <input
                        id='eventAddLogo'
                        name='eventAddLogo'
                        onChange={handleChange}
                        placeholder='Upload logo in Eloqua and insert link here...'
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.eventAddLogo}
                      />
                    </label>
                  </div>
                </div>

                <div className='grid grid-cols-1 xl:grid-cols-3 gap-4 w-full justify-center'>
                  <div className='inputContainer'>
                    <label htmlFor='eventTitle' className='grid'>
                      Event Title
                      <input
                        id='eventTitle'
                        name='eventTitle'
                        required='required'
                        onChange={handleChange}
                        placeholder='ex: Join HP at the game!'
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.eventTitle}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='eventSubTitle' className='grid'>
                      Event SubTitle
                      <input
                        id='eventSubTitle'
                        name='eventSubTitle'
                        required='required'
                        onChange={handleChange}
                        placeholder='ex: Big East Tournament Quarterfinal Games'
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.eventSubTitle}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='eventDetails' className='grid'>
                      Event Details
                      <input
                        id='eventDetails'
                        name='eventDetails'
                        required='required'
                        onChange={handleChange}
                        placeholder='ex: March 14 | 10 a.m. ET | HP Poly Office | Madison Square Garden '
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.eventDetails}
                      />
                    </label>
                  </div>
                </div>
                <div className='grid grid-cols-1 xl:grid-cols-2 gap-4 w-full justify-center'>
                  <div className='inputContainer'>
                    <label htmlFor='eventDate' className='grid'>
                      Event Date
                      <input
                        id='eventDate'
                        name='eventDate'
                        required='required'
                        onChange={handleChange}
                        placeholder='ex: March 14 | 10 a.m. ET | HP Poly Office | Madison Square Garden '
                        className='mt-2 mb-4 rounded-lg'
                        type='datetime-local'
                        value={formData.eventDate}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='eventTimeZone' className='grid'>
                      Event Time Zone
                      <select
                        id='eventTimeZone'
                        name='eventTimeZone'
                        required='required'
                        onChange={handleChange}
                        placeholder=''
                        className='mt-2 mb-4 rounded-lg'
                        value={formData.eventTimeZone}
                      >
                        <option value=''> - Please Select -</option>
                        <option value='ET'>ET</option>
                        <option value='CT'>CT</option>
                        <option value='MT'>MT</option>
                        <option value='PT'>PT</option>
                        <option value='GMT'>GMT</option>
                        <option value='CET'>CET</option>
                        <option value='IST'>IST</option>
                        <option value='JST'>JST</option>
                        <option value='AEST'>AEST</option>
                        <option value='NZST'>NZST</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div className='grid grid-cols-1 xl:grid-cols-2 gap-4 w-full justify-center'>
                  <div className='inputContainer'>
                    <label htmlFor='eventDescription' className='grid'>
                      Event Description
                      <textarea
                        id='eventDescription'
                        name='eventDescription'
                        required='required'
                        onChange={handleChange}
                        placeholder={`paragraph one\nparagraph two\nparagraph three`}
                        className='mt-2 mb-4 rounded-lg'
                        style={{ minHeight: '100px' }}
                        value={formData.eventDescription}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='eventAgenda' className='grid'>
                      Event Agenda
                      <textarea
                        id='eventAgenda'
                        name='eventAgenda'
                        required='required'
                        onChange={handleChange}
                        placeholder={`10:00 AM ET – Meeting at HP Poly Office\n11:00 AM ET – Suite access and lunch\n12:00 PM ET – Game 1 begins`}
                        className='mt-2 mb-4 rounded-lg'
                        style={{ minHeight: '100px' }}
                        value={formData.eventAgenda}
                      />
                    </label>
                  </div>
                </div>

                <div className='flex flex-row w-full justify-left items-center mt-20 mb-4'>
                  <p className='text-3xl'>Custom Fields</p>
                  <button
                    type='button'
                    className='text-3xl ml-3 default-link'
                    onClick={handleAddField}
                  >
                    +
                  </button>
                  {fields.map((field, index) => (
                    <div key={`${field.id}`}>
                      {index === fieldCount - 1 && fieldCount > 0 && (
                        <button
                          type='button'
                          className='text-3xl ml-3 default-link'
                          onClick={handleRemoveField}
                        >
                          -
                        </button>
                      )}
                    </div>
                  ))}
                </div>

                <div className='grid grid-cols-1 gap-4 w-full justify-center'>
                  {fields.map((field, index) => (
                    <React.Fragment key={`${field.id}`}>
                      <div>
                        <p className='text-xl mb-3 text-center'>Field {index + 1}</p>
                      </div>
                      <div className='inputContainer grid'>
                        <label htmlFor={`fieldType${index}`} className='grid'>
                          Type
                          <select
                            id={`fieldType${index}`}
                            name='type'
                            value={field.type}
                            required='required'
                            onChange={(e) => handleChangeField(index, e)}
                            placeholder=''
                            className='mt-2 mb-4 rounded-lg'
                            type='text'
                          >
                            <option value=''>- Please Select -</option>
                            <option value='input'>input</option>
                            <option value='select'>select</option>
                            <option value='textarea'>textarea</option>
                          </select>
                        </label>
                        {showOptions[index] ? (
                          <label htmlFor={`fieldLabel${index}`} className='grid'>
                            Options
                            <textarea
                              id={`fieldOptions${index}`}
                              name='options'
                              value={field.options}
                              required='required'
                              onChange={(e) => handleChangeField(index, e)}
                              placeholder={`option1\noption2\noption3`}
                              style={{ minHeight: '100px' }}
                              className='mt-2 mb-4 rounded-lg'
                            />
                          </label>
                        ) : null}
                      </div>
                      <div className='inputContainer grid grid-cols-1 xl:grid-cols-5 gap-4'>
                        <label htmlFor={`fieldLabel${index}`} className='grid'>
                          Label
                          <input
                            id={`fieldLabel${index}`}
                            name='label'
                            value={field.label}
                            required='required'
                            onChange={(e) => handleChangeField(index, e)}
                            placeholder=''
                            className='mt-2 mb-4 rounded-lg'
                            type='text'
                          />
                        </label>
                        <label htmlFor={`fieldName${index}`} className='grid'>
                          Name
                          <input
                            id={`fieldName${index}`}
                            name='name'
                            value={field.name}
                            required='required'
                            onChange={(e) => handleChangeField(index, e)}
                            placeholder=''
                            className='mt-2 mb-4 rounded-lg'
                            type='text'
                          />
                        </label>
                        <label htmlFor={`fieldDefaultValue${index}`} className='grid'>
                          Default Value
                          <input
                            id={`fieldDefaultValue${index}`}
                            name='defaultValue'
                            value={field.defaultValue}
                            onChange={(e) => handleChangeField(index, e)}
                            placeholder=''
                            className='mt-2 mb-4 rounded-lg'
                            type='text'
                          />
                        </label>
                        <label htmlFor={`fieldRequired${index}`} className='grid'>
                          Required
                          <select
                            id={`fieldRequired${index}`}
                            name='required'
                            value={field.required}
                            required='required'
                            onChange={(e) => handleChangeField(index, e)}
                            placeholder=''
                            className='mt-2 mb-4 rounded-lg'
                          >
                            <option value=''>- Please Select -</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                        </label>
                        <label htmlFor={`fieldExtra${index}`} className='grid'>
                          Conditional Extra Field
                          <select
                            id={`extraField${index}`}
                            name='extraField'
                            value={field.extraField}
                            required='required'
                            onChange={(e) => handleChangeField(index, e)}
                            placeholder=''
                            className='mt-2 mb-4 rounded-lg'
                          >
                            <option value=''>- Please Select -</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                        </label>
                      </div>
                      <div className='inputContainer grid grid-cols-1 xl:grid-cols-2 gap-4'>
                        {showExtraField[index] ? (
                          <label htmlFor={`fieldExtra${index}`} className='grid'>
                            Extra Field Type
                            <select
                              id={`extraFieldType${index}`}
                              name='extraFieldType'
                              value={field.extraFieldType}
                              required='required'
                              onChange={(e) => handleChangeField(index, e)}
                              placeholder=''
                              className='mt-2 mb-4 rounded-lg'
                            >
                              <option value=''>- Please Select -</option>
                              <option value='input'>input</option>
                              <option value='select'>select</option>
                              <option value='textarea'>textarea</option>
                            </select>
                          </label>
                        ) : null}
                        {showExtraField[index] ? (
                          <label htmlFor={`fieldExtra${index}`} className='grid'>
                            Extra Field Name
                            <input
                              id={`extraFieldName${index}`}
                              name='extraFieldName'
                              value={field.extraFieldName}
                              required='required'
                              onChange={(e) => handleChangeField(index, e)}
                              placeholder=''
                              className='mt-2 mb-4 rounded-lg'
                              type='text'
                            />
                          </label>
                        ) : null}
                        {showExtraField[index] ? (
                          <label htmlFor={`fieldExtra${index}`} className='grid'>
                            Extra Field Label
                            <input
                              id={`extraFieldLabel${index}`}
                              name='extraFieldLabel'
                              value={field.extraFieldLabel}
                              required='required'
                              onChange={(e) => handleChangeField(index, e)}
                              placeholder=''
                              className='mt-2 mb-4 rounded-lg'
                              type='text'
                            />
                          </label>
                        ) : null}
                        {showExtraField[index] ? (
                          <label htmlFor={`fieldExtra${index}`} className='grid'>
                            Extra Field Conditional Value
                            <input
                              id={`extraFieldCondValueShow${index}`}
                              name='extraFieldCondValueShow'
                              value={field.extraFieldCondValueShow}
                              required='required'
                              onChange={(e) => handleChangeField(index, e)}
                              placeholder='Please write the exact value from the field which triggers the display of the extra field.'
                              className='mt-2 mb-4 rounded-lg'
                              type='text'
                            />
                          </label>
                        ) : null}
                      </div>
                      {showExtraField[index] ? (
                        <div className='inputContainer grid grid-cols-1'>
                          {showExtraFieldOptions[index] ? (
                            <label htmlFor={`fieldLabel${index}`} className='grid'>
                              Extra Field Options
                              <textarea
                                id={`extraFieldOptions${index}`}
                                name='extraFieldOptions'
                                value={field.extraFieldOptions}
                                required='required'
                                onChange={(e) => handleChangeField(index, e)}
                                placeholder={`option1\noption2\noption3`}
                                style={{ minHeight: '100px' }}
                                className='mt-2 mb-4 rounded-lg'
                              />
                            </label>
                          ) : null}
                        </div>
                      ) : null}
                    </React.Fragment>
                  ))}
                </div>

                <div className='flex flex-row w-full justify-left items-center mt-20 mb-4'>
                  <p className='text-3xl pb-3'>Confirmation Page</p>
                </div>
                <div className='grid grid-cols-1 xl:grid-cols-2 gap-4 w-full justify-center'>
                  <div className='inputContainer'>
                    <label htmlFor='eventTY' className='grid'>
                      Event TY
                      <select
                        id='eventTY'
                        name='eventTY'
                        required='required'
                        onChange={handleChange}
                        className='mt-2 mb-4 rounded-lg'
                        value={formData.eventTY}
                      >
                        <option value=''>- Please Select -</option>
                        <option value='page'>Page</option>
                        <option value='message'>Message</option>
                      </select>
                    </label>
                  </div>
                  {eventTYP === 'page' ? (
                    <div className='inputContainer'>
                      <label htmlFor='eventTYURL' className='grid'>
                        Event TY Page URL
                        <input
                          id='eventTYURL'
                          name='eventTYURL'
                          required='required'
                          onChange={handleChange}
                          className='mt-2 mb-4 rounded-lg'
                          type='text'
                          value={formData.eventTYURL}
                        />
                      </label>
                    </div>
                  ) : null}
                  {eventTYP === 'message' ? (
                    <div className='inputContainer'>
                      <label htmlFor='eventTYTitle' className='grid'>
                        Event TY Message Title
                        <input
                          id='eventTYTitle'
                          name='eventTYTitle'
                          required='required'
                          onChange={handleChange}
                          placeholder='ex: Thank you for registering! '
                          className='mt-2 mb-4 rounded-lg'
                          type='text'
                          value={formData.eventTYTitle}
                        />
                      </label>
                    </div>
                  ) : null}
                </div>
                <div className='grid grid-cols-1 xl:grid-cols-1 gap-4 w-full justify-center'>
                  {eventTYP === 'message' ? (
                    <div className='inputContainer'>
                      <label htmlFor='eventTYMessage' className='grid'>
                        Event TY Message Content
                        <textarea
                          id='eventTYMessage'
                          name='eventTYMessage'
                          required='required'
                          onChange={handleChange}
                          placeholder={`paragraph one\nparagraph two\nparagraph three`}
                          className='mt-2 mb-4 rounded-lg'
                          style={{ minHeight: '100px' }}
                          value={formData.eventTYMessage}
                        />
                      </label>
                    </div>
                  ) : null}
                </div>

                <div className='flex flex-col w-full justify-left mt-20 mb-4'>
                  <p className='text-3xl'>Confirmation Mail</p>
                  <p className='text-md pb-3'>Eloqua Footer - Forma 20 Black</p>
                </div>

                <div className='grid grid-cols-1 xl:grid-cols-4 gap-4 w-full justify-center'>
                  <div className='inputContainer'>
                    <label htmlFor='mailTitle' className='grid'>
                      Mail Title
                      <input
                        id='mailTitle'
                        name='mailTitle'
                        required='required'
                        onChange={handleChange}
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.mailTitle}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='mailSubTitle' className='grid'>
                      Mail SubTitle
                      <input
                        id='mailSubTitle'
                        name='mailSubTitle'
                        required='required'
                        onChange={handleChange}
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.mailSubTitle}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='mailGoogleCalendar' className='grid'>
                      Mail Google Calendar Link
                      <input
                        id='mailGoogleCalendar'
                        name='mailGoogleCalendar'
                        required='required'
                        onChange={handleChange}
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.mailGoogleCalendar}
                      />
                    </label>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='mailOutlookCalendar' className='grid'>
                      Mail Outlook Calendar Link
                      <input
                        id='mailOutlookCalendar'
                        name='mailOutlookCalendar'
                        required='required'
                        onChange={handleChange}
                        className='mt-2 mb-4 rounded-lg'
                        type='text'
                        value={formData.mailOutlookCalendar}
                      />
                    </label>
                  </div>
                </div>
                <div className='grid grid-cols-1 xl:grid-cols-1 gap-4 w-full justify-center'>
                  <div className='inputContainer'>
                    <label htmlFor='mailContent' className='grid'>
                      Mail Content
                      <textarea
                        id='mailContent'
                        name='mailContent'
                        required='required'
                        onChange={handleChange}
                        placeholder={`paragraph one\nparagraph two\nparagraph three`}
                        className='mt-2 mb-4 rounded-lg'
                        style={{ minHeight: '100px' }}
                        value={formData.mailContent}
                      />
                    </label>
                  </div>
                </div>

                <div className='flex flex-row w-full justify-left items-center mt-20 mb-4'>
                  <p className='text-3xl'>Event Address</p>
                  {addresses.map((address, index) => (
                    <div key={`${address.id}`}>
                      {index === addressCount && (
                        <button
                          type='button'
                          className='text-3xl ml-3 default-link'
                          onClick={handleAddAddress}
                        >
                          +
                        </button>
                      )}
                      {index === addressCount && addressCount >= 1 && (
                        <button
                          type='button'
                          className='text-3xl ml-3 default-link'
                          onClick={handleRemoveAddress}
                        >
                          -
                        </button>
                      )}
                    </div>
                  ))}
                </div>

                <div className='grid grid-cols-1 xl:grid-cols-2 gap-4 w-full justify-center'>
                  {addresses.map((address, index) => (
                    <React.Fragment key={`${address.id}`}>
                      <div className='inputContainer'>
                        <p className='text-xl mb-3 text-center'>Address {index + 1}</p>
                        <label htmlFor={`eventAddressTitle${index}`} className='grid'>
                          Title
                          <input
                            id={`eventAddressTitle${index}`}
                            name='title'
                            value={address.title}
                            required='required'
                            onChange={(e) => handleChangeAddress(index, e)}
                            placeholder=''
                            className='mt-2 mb-4 rounded-lg'
                            type='text'
                          />
                        </label>
                        <label htmlFor={`eventAddressText${index}`} className='grid'>
                          Text
                          <textarea
                            id={`eventAddressText${index}`}
                            name='text'
                            value={address.text}
                            required='required'
                            onChange={(e) => handleChangeAddress(index, e)}
                            placeholder={`address line one\naddress line two`}
                            className='mt-2 mb-4 rounded-lg'
                            type='text'
                          />
                        </label>
                        <label htmlFor={`eventAddressLink${index}`} className='grid'>
                          Link
                          <input
                            id={`eventAddressLink${index}`}
                            name='link'
                            value={address.link}
                            required='required'
                            onChange={(e) => handleChangeAddress(index, e)}
                            placeholder=''
                            className='mt-2 mb-4 rounded-lg'
                            type='text'
                          />
                        </label>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                {/* <button type='button' onClick={handleClick}>Console log</button> */}
                <button type='submit' className='button my-24'>
                  Download HTML
                </button>
              </form>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default EventBuilder;
