import firebase from 'firebase/compat/app';
import React, { createContext, useReducer } from 'react';
import { firebaseConfig } from '../../firebaseConfig';
import { userReducer, USER_REDUCER_INITIAL_STATE } from './UserReducer';

import 'firebase/compat/auth';
import 'firebase/compat/database';

import { SET_USER, SET_USER_LOGGED_IN_STATE } from './types';

export const UserContext = createContext();

export function UserProvider({ children }) {
  const [{ user, isLoggedIn }, dispatch] = useReducer(userReducer, USER_REDUCER_INITIAL_STATE);

  function onAuthStateChanged(callback, prevState) {
    return firebase.auth().onAuthStateChanged((userFound) => {
      if (userFound) {
        callback(true);
      } else {
        callback(false);
      }
    });
  }

  React.useEffect(() => {
    const unsubscribeFromAuthStateListener = onAuthStateChanged((state) => {
      dispatch({ type: SET_USER_LOGGED_IN_STATE, payload: state });
    });
    return () => {
      unsubscribeFromAuthStateListener();
    };
  }, []);

  React.useEffect(() => {
    if (firebase.apps.length && firebase.auth().currentUser)
      firebase
        .database()
        .ref(`/users/${firebase.auth().currentUser.uid}`)
        .on('value', (data) => {
          if (data.val()) {
            dispatch({
              type: SET_USER,
              payload: { ...data.val(), id: firebase.auth().currentUser.uid },
            });
          } else
            dispatch({
              type: SET_USER,
              payload: { ...USER_REDUCER_INITIAL_STATE, id: firebase.auth().currentUser.uid },
            });
        });
  }, [isLoggedIn]);

  // Function to handle logout
  const handleLogout = async () => {
    await firebase.auth().signOut();
    if (firebase.apps.length) {
      await firebase.app().delete();
      firebase.initializeApp(firebaseConfig);
    }
    window.location.reload();
  };

  const contextValue = React.useMemo(
    () => ({ user, isLoggedIn, handleLogout }),
    [user, isLoggedIn],
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}

export function useUserContext() {
  return React.useContext(UserContext);
}
