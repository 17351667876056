import React from 'react';
import './App.css';
import 'grapesjs/dist/css/grapes.min.css';
import firebase from 'firebase/compat/app';
import { firebaseConfig } from './firebaseConfig';
import { UserProvider } from './contexts/UserProvider/UserProvider';
import AppMain from './AppMain';

firebase.initializeApp(firebaseConfig);

export default function App() {
  return (
    <div className='w-full'>
      <UserProvider>
        <AppMain />
      </UserProvider>
    </div>
  );
}
