import { SET_USER, SET_USER_LOGGED_IN_STATE } from './types';

export const USER_REDUCER_INITIAL_STATE = {
  user: { id: 'loading', email: '', access: 'pending', isAdmin: false },
  isLoggedIn: null,
};

export const userReducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: { ...action.payload } };
    case SET_USER_LOGGED_IN_STATE:
      return { ...state, isLoggedIn: action.payload };
    default:
      return { ...state };
  }
};
