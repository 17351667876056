function EventBuilderMail({ data }) {
  const {
    bmtID,
    banner,
    eventTheme,
    mailTitle,
    mailSubTitle,
    mailGoogleCalendar,
    mailOutlookCalendar,
    mailContent,
  } = data;

  const colors = {
    blue1: {
      headColor: '#0096d6',
      contentColor: '#4db6e2',
      formColor: '#99d5ef',
      counterColor: '#cceaf7',
    },
    blue2: {
      headColor: '#4759f5',
      contentColor: '#7e8bf8',
      formColor: '#b6bdfb',
      counterColor: '#dadefd',
    },
    blue3: {
      headColor: '#549ef7',
      contentColor: '#87bbfa',
      formColor: '#bbd8fc',
      counterColor: '#ddecfd',
    },
    blue4: {
      headColor: '#40defa',
      contentColor: '#79e8fb',
      formColor: '#b2f2fd',
      counterColor: '#d9f8fe',
    },
    green1: {
      headColor: '#00d072',
      contentColor: '#4dde9c',
      formColor: '#99ecc7',
      counterColor: '#ccf6e3',
    },
    green2: {
      headColor: '#4ef5ab',
      contentColor: '#83f8c4',
      formColor: '#b8fbdd',
      counterColor: '#dcfdee',
    },
    green3: {
      headColor: '#83f955',
      contentColor: '#a8fb88',
      formColor: '#ccfdbb',
      counterColor: '#e6fedd',
    },
    green4: {
      headColor: '#d3fb66',
      contentColor: '#e0fc94',
      formColor: '#edfdc2',
      counterColor: '#f6fee0',
    },
    purple1: {
      headColor: '#b655f5',
      contentColor: '#cc88f8',
      formColor: '#e2bbfb',
      counterColor: '#f0ddfd',
    },
    purple2: {
      headColor: '#a89efa',
      contentColor: '#c2bbfb',
      formColor: '#dcd8fd',
      counterColor: '#eeecfe',
    },
    pink1: {
      headColor: '#ff63bb',
      contentColor: '#ff92cf',
      formColor: '#ffc1e4',
      counterColor: '#ffe0f1',
    },
    pink2: {
      headColor: '#ff84ff',
      contentColor: '#ffa9ff',
      formColor: '#ffceff',
      counterColor: '#ffe7ff',
    },
    pink3: {
      headColor: '#ff948a',
      contentColor: '#ffb4ad',
      formColor: '#ffd4d0',
      counterColor: '#ffeae8',
    },
    orange1: {
      headColor: '#ff8900',
      contentColor: '#ffac4d',
      formColor: '#ffd099',
      counterColor: '#ffe7cc',
    },
    orange2: {
      headColor: '#ffbd3b',
      contentColor: '#ffd176',
      formColor: '#ffe4b1',
      counterColor: '#ffe7cc',
    },
    yellow1: {
      headColor: '#ffe137',
      contentColor: '#ffea73',
      formColor: '#fff3af',
      counterColor: '#fff9d7',
    },
    yellow2: {
      headColor: '#fcf54b',
      contentColor: '#fdf881',
      formColor: '#fefbb7',
      counterColor: '#fefddb',
    },
    brown1: {
      headColor: '#856630',
      contentColor: '#aa946e',
      formColor: '#cec2ac',
      counterColor: '#e7e0d6',
    },
    darkRed1: {
      headColor: '#4b0f22',
      contentColor: '#815764',
      formColor: '#b79fa7',
      counterColor: '#dbcfd3',
    },
    darkBlue1: {
      headColor: '#212248',
      contentColor: '#64647f',
      formColor: '#a6a7b6',
      counterColor: '#d3d3da',
    },
    darkGreen1: {
      headColor: '#0c4508',
      contentColor: '#557d52',
      formColor: '#9eb59c',
      counterColor: '#cedace',
    },
    darkGrey1: {
      headColor: '#221f20',
      contentColor: '#656263',
      formColor: '#a7a5a6',
      counterColor: '#d3d2d2',
    },
  };

  const mailText = mailContent
    .split('\n')
    .filter((segment) => segment.trim() !== '')
    .map((segment) => `${segment}<br /><br />`)
    .join('');

  // Constructing the code
  const code = `
<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns="http://www.w3.org/1999/xhtml">

<head>
    <meta charset="utf-8">
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width">
    <meta name="x-apple-disable-message-reformatting">
    <title>HP | Official Site</title>
    <!--[if gte mso 9]><xml>
    <o:OfficeDocumentSettings>
    <o:AllowPNG/>
    <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml><![endif]-->
    <style type="text/css">
        @import url("https://use.typekit.net/jbt7rox.css");
    </style>
    <!--[if gt mso 15]>
    <style type="text/css" media="all">
    /* Outlook 2016 Height Fix */
    table, tr, td {border-collapse: collapse;}
    tr { font-size:0px; line-height:0px; border-collapse: collapse; }
    </style>
    <![endif]-->
    <style type="text/css">
        .ReadMsgBody {
            width: 100%;
        }

        body {
            width: 100% !important;
            -webkit-text-adjust: 100%;
            -webkit-text-size-adjust: 100%;
            -ms-text-adjust: 100%;
            margin: 0 !important;
            padding: 0;
        }

        table {
            border-collapse: collapse !important;
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
            border-width: 0px !important;
        }

        sup,
        sub {
            vertical-align: baseline;
            position: relative;
            top: -0.4em;
            font-size: 85%;
        }

        a {
            text-decoration: none;
        }

        div[style*="margin: 16px 0;"] {
            margin: 0 !important;
        }

        div {
            margin: 0 !important;
            padding: 0 !important;
        }

        span>a {
            color: inherit !important;
            text-decoration: none !important;
        }

        .footer span>a {
            color: #760075 !important;
        }

        table .show,
        .show,
        .show * {
            display: none;
            max-height: 0;
            max-width: 0;
            height: 0;
            width: 0px;
            padding: 0;
            margin: 0;
            visibility: hidden;
            line-height: 0px;
            font-size: 0px;
            background: none;
            mso-hide: all !important;
            mso-line-height-rule: exactly;
        }

        .sapMktBlock .view-online {
            display: none;
        }

        .mob-spacer {
            display: none;
        }

        .contact-mobile {
            display: none !important;
        }

        input {
            display: none;
        }

        @media screen and (max-width:9999px) {
            .fallback-alt {
                width: 100%;
                margin: 0 auto;
                text-align: center;
            }

            .thumb-fallback {
                width: 140px;
                display: inline-block;
            }
        }

        @media screen and (max-width:321px) {
            .block-padding {
                padding: 8px !important;
            }

            .block-subtitle {
                font-size: 10px !important;
                padding: 0px 0px 5px 0px !important;
                line-height: 15px;
            }

            .block-heading {
                font-size: 13px !important;
                line-height: 13px !important;
                padding: 0px 0px 5px 0px !important;
            }

            .block-text {
                font-size: 12px !important;
                line-height: 12px !important;
                padding-bottom: 0px !important;
            }

            .block-text-cta {
                font-size: 11px !important;
                padding: 4px 0 !important;
            }

            .block-cta-height {
                height: 20px !important;
            }
        }
    </style>
    <style type="text/css">
        /**** hide mobile images **/
        .mobile-display {
            display: none !important;
            visibility: none;
        }

        * {
            font-family: forma-djr-text, Arial, Calibri, sans-serif;
        }

        ul li {
            font-family: forma-djr-text, Arial, Calibri, sans-serif;
        }

        strong {
            font-family: forma-djr-text, Arial, Calibri, sans-serif !important;
        }

        /**** specific CSS */
        a {
            color: #ffffff;
            text-decoration: underline !important;
            font-weight: bold;
        }

        .footerSection a {
            color: #ffffff !important;
            text-decoration: underline !important;
        }

        table.footerSection a {
            color: #ffffff !important;
            text-decoration: underline !important;
        }

        table.footerSection,
        table.footerSection td,
        table.footerSection * {
            /* #0196d6 */
        }

        a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }

        u+#body a {
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }

        #MessageViewBody a {
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }

        .customURL a {
            color: #ffffff !important;
            text-decoration: none !important;
        }

        .customURL-black a {
            color: #ffffff !important;
            text-decoration: underline !important;
        }

        @media screen and (max-width: 599px) {

            .mj-column-per-100,
            .mj-column-per-48,
            .mj-column-per-52,
            .mj-column-per-61,
            .mj-column-per-39,
            .mj-column-per-44,
            .mj-column-per-56,
            .mj-column-per-53,
            .mj-column-per-47 {
                width: 100% !important;
            }

            table {}

            .mobile-default {
                width: 100% !important;
            }

            .mobile-block {
                width: 100% !important;
                min-width: 100% !important;
                max-width: 100% !important;
                display: block !important;
                height: auto !important;
                max-height: 9999px !important;
                text-align: left !important;
            }

            .mobile-cell {
                display: block;
                box-sizing: border-box !important;
                height: auto !important;
                width: 100% !important;
                min-width: 100% !important;
                max-width: 100% !important;
                display: block !important;
                height: auto !important;
                max-height: 9999px !important;
                text-align: left;
            }

            .mobile-padding {
                width: 25px;
            }

            .mobile-padding-none {
                width: 0;
            }

            .mobile-padding-right {
                padding-right: 25px;
            }

            .mobile-title {}

            .title-center {
                text-align: center !important;
                display: block;
            }

            .mobile-text {
                font-size: 15px !important;
                /* text-align: left !important; */
            }

            .text-space {
                padding: 15px 0 !important;
            }

            .mobile-photo {
                width: 100% !important;
            }

            .mobile-photo img {
                width: 100% !important;
                height: auto !important;
            }

            .mobile-table {
                width: calc(33.33% - 40px) !important;
                min-width: 30px !important;
                max-width: 200px;
            }

            .mobile-table img {
                width: 90% !important;
            }

            .mobile-info {
                width: 100%;
                display: inline-block;
                max-width: 599px !important;
                padding-bottom: 7px;
            }

            .mobile-space {
                height: 10px !important;
            }

            /******/
            .mobile-center {
                text-align: center;
            }

            .mobile-border {
                border-left: 1px solid #212121;
                border-right: 1px solid #212121;
            }

            .mobile-100 {
                width: 100% !important;
            }

            /**** HIDE and DISPLAY mobile images **/
            .mobile-display {
                display: block !important;
            }

            .mobile-img,
            .mobile-hide {
                width: 100% !important;
                display: none !important;
                visibility: hidden !important;
                mso-hide: all;
            }

            /**** specific CSS **/
            .mobile-link {
                width: calc(100vw - 30px) !important;
                text-align: right !important;
            }

            .hero__columns td {
                padding: 0 25px;
            }

            .hero__columns .hero__two {
                background: white !important;
                width: 100vw !important;
            }

            .grey__columns td {
                padding: 0 25px;
            }

            span.title-section {
                font-size: 40px !important;
                line-height: 48px !important;
            }

            .mobile-border {
                border-left: none !important;
                border-right: none !important;
            }

            .mobile-height {
                min-height: auto !important;
            }

            .btn-space {
                display: block !important;
                width: 100% !important;
            }
        }

        span[x-apple-data-detectors] {
            color: #000000 !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }

        a[x-apple-data-detectors] {
            color: #000000 !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }
    </style>
</head>

<body style="padding: 0;" id="body" vlink="#ffffff" link="#ffffff" alink="#ffffff">
    <table class="mobile-block" align="center" bgcolor="#fce15c" border="0" cellpadding="0" cellspacing="0" width="600"
        style="width:600px; min-width:600px; max-width:600px; background-color: #ffffff;">
        <tr>
            <td class="mobile-cell" width="600" align="center" valign="top" bgcolor="#ffffff"
                style="width:600px; min-width:600px; max-width:600px; background-color: #ffffff;">
                <!-- WEBVIEW
          ========================================================= -->
                <table class="mobile-block" align="center" bgcolor="#ffffff" border="0" cellpadding="0" cellspacing="0"
                    width="600" style="width:600px; min-width:600px; max-width:600px;">
                    <tr>
                        <td class="mobile-padding" width="25"><img alt=""
                                src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B3af50d96-770c-40bb-a82d-5bdc406b0a80%7D_x.gif"
                                style="display:block; border:0;"></td>
                        <td class="mobile-cell mobile-link" width="575" align="right" valign="top" bgcolor="#ffffff"
                            style="width:575px; min-width:575px; max-width:575px; font-family: forma-djr-text, Arial, Calibri, sans-serif;">
                            <p class="mobile-text"
                                style="color: #ffffff; font-size: 10px; line-height: 10px; display: block; margin: 0; width: 100%;">
                                &nbsp;</p>
                            <span class="customURL-black">
                                &nbsp;&nbsp;<a
                                    style="font-family: forma-djr-text, Arial, sans-serif, Calibri; font-size: 12px; line-height: 15px; font-weight: 500; color: black !important; text-decoration: underline !important;"
                                    color="black"
                                    href="https://app.marketingcontent.ext.hp.com/e/es?s=~~eloqua..type--emailfield..syntax--siteid..encodeFor--url~~&e=~~eloqua..type--emailfield..syntax--elqemailsaveguid..encodeFor--url~~&elqTrackId=634bc5f4bdbe4be68fdcf24bb763a684"
                                    data-targettype="sysaction">View in browser</a>&nbsp;&nbsp;
                            </span>
                            <p class="mobile-text"
                                style="color: #ffffff; font-size: 10px; line-height: 10px; display: block; margin: 0; width: 100%;">
                                &nbsp;</p>
                        </td>
                    </tr>
                </table>
                <table class="mobile-block" align="center" bgcolor="${colors[eventTheme].headColor}" border="0" cellpadding="0" cellspacing="0"
                    width="600" style="width:600px; min-width:600px; max-width:600px;">
                    <tr class="hero__columns">
                        <td class="mobile-hide" width="10"><img alt=""
                                src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B3af50d96-770c-40bb-a82d-5bdc406b0a80%7D_x.gif"
                                style="display:block; border:0;"></td>
                        <td class="mobile-cell hero__one" width="580" align="left" valign="middle" bgcolor="${colors[eventTheme].headColor}"
                            style="width:580px; min-width:580px; max-width:580px; font-family: forma-djr-text, Arial, Calibri, sans-serif;">
                            <p class="mobile-text"
                                style="color: ${colors[eventTheme].headColor}; font-size: 10px; line-height: 10px; display: block; padding:0;margin: 0; width: 100%;">
                                &nbsp;</p>
                            <img class="hp-logo" width="50"
                                    src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B72c2ebc3-2209-48dc-b433-c00b434ad45c%7D_HP_Black_RGB_150_MD.png"
                                    style="width: 50px; max-width: 50px;">
                            <p class="mobile-text"
                                style="color: ${colors[eventTheme].headColor}; font-size: 10px; line-height: 10px; display: block; margin: 0; width: 100%;">
                                &nbsp;</p>
                        </td>
                        <td class="mobile-hide" width="10"><img alt=""
                                src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B3af50d96-770c-40bb-a82d-5bdc406b0a80%7D_x.gif"
                                style="display:block; border:0;">
                        </td>
                    </tr>
                </table>
                <!-- HERO
          ========================================================= -->
                <table class="mobile-block" align="center" bgcolor="${colors[eventTheme].headColor}" border="0" cellpadding="0" cellspacing="0"
                    width="600" style="width:600px; min-width:600px; max-width:600px;">
                    <tr>
                        <td class="mobile-cell" width="600" align="left" valign="top" bgcolor="${colors[eventTheme].headColor}"
                            style="width:600px; min-width:600px; max-width:600px; font-family: forma-djr-text, Arial, Calibri, sans-serif;">
                            <img class="mobile-100"
                                src="${banner}"
                                style="width: 600px; max-width: 600px; border:none;" width="600" alt="" border="0">
                        </td>
                    </tr>
                </table>
                <!-- TITLE
          ========================================================= -->
                <table class="mobile-block" align="center" bgcolor="${colors[eventTheme].headColor}" border="0" cellpadding="0" cellspacing="0"
                    width="600" style="width:600px; min-width:600px; max-width:600px;">
                    <tr>
                        <td class="mobile-padding" width="15"><img alt=""
                                src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B3af50d96-770c-40bb-a82d-5bdc406b0a80%7D_x.gif"
                                style="display:block; border:0;">
                        </td>
                        <td class="mobile-cell" width="570" align="left" valign="top" bgcolor="${colors[eventTheme].headColor}"
                            style="width:570px; min-width:570px; max-width:570px; font-family: forma-djr-text, Arial, sans-serif, Calibri;">
                            <p class="mobile-text"
                                style="color: ${colors[eventTheme].headColor}; font-size: 10px; line-height: 10px; display: block; margin: 0; width: 100%;">
                                &nbsp;</p>
                            <span class="title-section"
                                style="font-family: forma-djr-text, Arial, Calibri, sans-serif; font-size: 35px; line-height: 40px; color: #000000; letter-spacing: -1px;">
                                ${mailTitle}
                            </span>
                            <p class="mobile-text"
                                style="color: ${colors[eventTheme].headColor}; font-size: 10px; line-height: 10px; display: block; margin: 0; width: 100%;">
                                &nbsp;</p>
                        </td>
                        <td class="mobile-padding" width="15"><img alt=""
                                src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B3af50d96-770c-40bb-a82d-5bdc406b0a80%7D_x.gif"
                                style="display:block; border:0;">
                        </td>
                    </tr>
                </table>
                <!-- SUBTITLE
          ========================================================= -->
                <table class="mobile-block" align="center" bgcolor="${colors[eventTheme].contentColor}" border="0" cellpadding="0" cellspacing="0"
                    width="600" style="width:600px; min-width:600px; max-width:600px;">
                    <tr>
                        <td class="mobile-padding" width="15"><img alt=""
                                src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B3af50d96-770c-40bb-a82d-5bdc406b0a80%7D_x.gif"
                                style="display:block; border:0;">
                        </td>
                        <td class="mobile-cell" width="570" align="left" valign="top" bgcolor="${colors[eventTheme].contentColor}"
                            style="width:570px; min-width:570px; max-width:570px; font-family: forma-djr-text, Arial, sans-serif, Calibri;">
                            <p class="mobile-text"
                                style="color: ${colors[eventTheme].contentColor}; font-size: 10px; line-height: 10px; display: block; margin: 0; width: 100%;">
                                &nbsp;</p>
                            <a
                                style="color: #000000; text-decoration: none !important; font-weight: normal !important;"><span
                                    class="txt-section"
                                    style="font-family: forma-djr-text, Arial, Calibri, sans-serif; font-size: 20px; line-height: 24px; color: #000000; letter-spacing: -0.2px;">
                                    ${mailSubTitle}        
                            </span></a>
                            <p class="mobile-text"
                                style="color: ${colors[eventTheme].contentColor}; font-size: 10px; line-height: 10px; display: block; margin: 0; width: 100%;">
                                &nbsp;</p>
                        </td>
                        <td class="mobile-padding" width="15"><img alt=""
                                src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B3af50d96-770c-40bb-a82d-5bdc406b0a80%7D_x.gif"
                                style="display:block; border:0;">
                        </td>
                    </tr>
                </table>
                <table class="mobile-block" align="center" bgcolor="${colors[eventTheme].formColor}" border="0" cellpadding="0" cellspacing="0"
                    width="600" style="width:600px; min-width:600px; max-width:600px;">
                    <tr>
                        <td class="mobile-padding" width="15"><img alt=""
                                src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B3af50d96-770c-40bb-a82d-5bdc406b0a80%7D_x.gif"
                                style="display:block; border:0;">
                        </td>
                        <td class="mobile-cell" width="570" align="left" valign="top" bgcolor="${colors[eventTheme].formColor}"
                            style="width:570px; min-width:570px; max-width:570px; font-family: forma-djr-text, Arial, sans-serif, Calibri;">
                            <p class="mobile-text"
                                style="color: ${colors[eventTheme].formColor}; font-size: 30px; line-height: 30px; display: block; margin: 0; width: 100%; padding: none;">
                                &nbsp;
                            </p>
                            <a
                                style="color: #000000; text-decoration: none !important; font-weight: normal !important;"><span
                                    class="txt-section"
                                    style="font-family: forma-djr-text, Arial, Calibri, sans-serif; font-size: 15px; line-height: 18px; color: #000000; ">
									${mailText}
                                </span></a>
                        </td>
                        <td class="mobile-padding" width="15"><img alt=""
                                src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B3af50d96-770c-40bb-a82d-5bdc406b0a80%7D_x.gif"
                                style="display:block; border:0;">
                        </td>
                    </tr>
                </table>
                <table class="mobile-block" align="center" bgcolor="${colors[eventTheme].formColor}" border="0" cellpadding="0" cellspacing="0"
                    width="600" style="width:600px; min-width:600px; max-width:600px;">
                    <tr>
                        <td class="mobile-padding" width="15"><img alt=""
                                src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B3af50d96-770c-40bb-a82d-5bdc406b0a80%7D_x.gif"
                                style="display:block; border:0;">
                        </td>
                        <td class="mobile-cell" width="240" align="left" valign="top" bgcolor="${colors[eventTheme].formColor}"
                            style="width:240px; min-width:240px; max-width:240px; font-family: forma-djr-text, Arial, Calibri, sans-serif;">
                            <!-- button -->
                            <a rel="noopener" target="_blank"
                                href="${mailGoogleCalendar}&bmtid=${bmtID}"
                                style="background-color: #000000; font-size: 14px; font-family: forma-djr-text, Arial, Calibri, sans-serif; text-decoration: none !important; padding: 7px 14px; color: #ffffff !important; font-weight: normal !important; display: inline-block; mso-padding-alt: 0;">
                                <!--[if mso]>
                  <i style="letter-spacing: 25px; mso-font-width: -100%; mso-text-raise: 11pt;">&nbsp;</i>
                  <![endif]-->
                                <span
                                    style="mso-text-raise: 6pt; font-family: forma-djr-text, Arial, Calibri, sans-serif; font-size: 14px; line-height: 14px; color: #ffffff; text-decoration: none !important; font-weight: normal !important;">
                                    Add to Google Calendar
                                </span>
                                <!--[if mso]>
                  <i style="letter-spacing: 25px; mso-font-width: -100%;">&nbsp;</i>
                  <![endif]-->
                            </a>
                            <p class="mobile-text"
                                style="color: ${colors[eventTheme].formColor}; font-size: 30px; line-height: 20px; display: block; margin: 0; width: 100%;">
                                &nbsp;</p>
                        </td>
                        <td class="mobile-cell" width="5"><img alt=""
                                src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B3af50d96-770c-40bb-a82d-5bdc406b0a80%7D_x.gif"
                                style="display:block; border:0;">
                        </td>
                        <td class="mobile-cell" width="240" align="left" valign="top" bgcolor="${colors[eventTheme].formColor}"
                            style="width:240px; min-width:240px; max-width:240px; font-family: forma-djr-text, Arial, Calibri, sans-serif;">
                            <!-- button -->
                            <a rel="noopener" target="_blank"
                                href="${mailOutlookCalendar}?bmtid=${bmtID}"
                                style="background-color: #000000; font-size: 14px; font-family: forma-djr-text, Arial, Calibri, sans-serif; text-decoration: none !important; padding: 7px 14px; color: #ffffff !important; font-weight: normal !important; display: inline-block; mso-padding-alt: 0;">
                                <!--[if mso]>
                                <i style="letter-spacing: 25px; mso-font-width: -100%; mso-text-raise: 11pt;">&nbsp;</i>
                                <![endif]-->
                                <span
                                    style="mso-text-raise: 6pt; font-family: forma-djr-text, Arial, Calibri, sans-serif; font-size: 14px; line-height: 14px; color: #ffffff; text-decoration: none !important; font-weight: normal !important;">
                                    Add to Outlook Calendar
                                </span>
                                <!--[if mso]>
                                <i style="letter-spacing: 25px; mso-font-width: -100%;">&nbsp;</i>
                                <![endif]-->
                            </a>
                            <p class="mobile-text"
                                style="color: ${colors[eventTheme].formColor}; font-size: 30px; line-height: 20px; display: block; margin: 0; width: 100%;">
                                &nbsp;</p>
                        </td>
                        <td class="mobile-padding" width="85"><img alt=""
                                src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B3af50d96-770c-40bb-a82d-5bdc406b0a80%7D_x.gif"
                                style="display:block; border:0;">
                        </td>
                        <td class="mobile-padding" width="15"><img alt=""
                                src="https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B3af50d96-770c-40bb-a82d-5bdc406b0a80%7D_x.gif"
                                style="display:block; border:0;">
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</body>

</html>
    `;

  return code;
}

export default EventBuilderMail;
