import './HomeScreen.css';
import React from 'react';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { GeneralContext } from '../../contexts/GeneralContext';
import { useUserContext } from '../../contexts/UserProvider/UserProvider';

function HomeScreen() {
  const { user, isLoggedIn } = useUserContext();
  const state = React.useContext(GeneralContext);
  const [pageInfo, setPageInfo] = React.useState([]);

  const renderPageInfo = (element, index) => (
    <h1 key={index} className='text-5xl max-w-3xl text-white pt-16'>
      {element.homeTitle}
    </h1>
  );

  React.useEffect(() => {
    const elements = state
      ? Object.keys(state).map((index) => renderPageInfo(state[index], index))
      : [];
    setPageInfo(elements);
  }, [state]);

  const lpTypes = [
    {
      name: 'Default Event - VisID',
      id: 'event',
      admin: false,
      picture:
        'https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Default_Event.jpg?alt=media&token=c17dff73-a997-4658-abc7-a873c2e2c1a3',
      hidden: false,
    },
    {
      name: 'Default Webinar - VisID',
      id: 'webinar',
      admin: false,
      picture:
        'https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/Default_Webinar.jpg?alt=media&token=03aa98c5-e503-41d1-9fdc-b021517c6467',
      hidden: false,
    },
    {
      name: 'Build Custom Layout',
      id: 'build',
      admin: true,
      picture:
        'https://ux.hp.com/content/dam/sites/worldwide/ux/new-edited-visid-resources-small.png',
      hidden: false,
    },
  ];

  const [showModal, setShowModal] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [error, setError] = React.useState('');
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setInputValue('');
    setError('');
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleNextClick = () => {
    if (!inputValue.trim()) {
      setError('Field cannot be empty or contain only spaces.');
    } else {
      setError('');
      // Navigate to a route in the app
      navigate(`/build/${inputValue}`);
    }
  };

  const renderLPTypes = () => {
    if (lpTypes)
      return lpTypes.map((item) => {
        if (user.isAdmin === false && item.hidden !== true && item.admin !== true) {
          return (
            <Link
              key={`lp_type_${item.id}`}
              to={`/${item.id}`}
              style={{ backgroundColor: '#fff' }}
              className='flex w-full col-span-4 h-fit md:col-span-2 xl:col-span-1 my-2 flex-col remove-decoration rounded-xl shadow hover:-translate-y-4 transform transition-transform duration-300'
            >
              <div
                className='py-3 px-4 rounded-t-xl'
                style={{ backgroundColor: '#ffffff', borderBottom: '1px solid #d4d4d4' }}
              >
                <p className='font-forma-djr text-gray text-xl'>{item.name}</p>
              </div>
              <img alt='' className='rounded-b-xl w-full' src={item.picture} />
            </Link>
          );
        }
        if (user.isAdmin === true && item.hidden !== true) {
          return (
            <div>
              {item.admin === false ? (
                <Link
                  key={`lp_type_${item.id}`}
                  to={`/${item.id}`}
                  style={{ backgroundColor: '#fff' }}
                  className='flex w-full col-span-4 h-fit md:col-span-2 xl:col-span-1 my-2 flex-col remove-decoration rounded-xl shadow hover:-translate-y-4 transform transition-transform duration-300'
                >
                  <div
                    className='py-3 px-4 rounded-t-xl'
                    style={{ backgroundColor: '#ffffff', borderBottom: '1px solid #d4d4d4' }}
                  >
                    <p className='font-forma-djr text-gray text-xl'>{item.name}</p>
                  </div>
                  <img alt='' className='rounded-b-xl w-full' src={item.picture} />
                </Link>
              ) : (
                <button
                  type='button'
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => {
                    handleOpenModal();
                  }}
                  className='flex w-full col-span-4 h-fit md:col-span-2 xl:col-span-1 my-2 flex-col remove-decoration rounded-xl shadow hover:-translate-y-4 transform transition-transform duration-300'
                >
                  <div
                    className='py-3 px-4 rounded-t-xl'
                    style={{ backgroundColor: '#ffffff', borderBottom: '1px solid #d4d4d4' }}
                  >
                    <p className='font-forma-djr text-gray text-xl'>{item.name}</p>
                  </div>
                  <img alt='' className='rounded-b-xl w-full' src={item.picture} />
                </button>
              )}
            </div>
          );
        }
        return null;
      });
    return null;
  };

  return (
    <div className='App'>
      {isLoggedIn === false ? (
        <Navigate to='/login' />
      ) : (
        user.access === 'approved' && (
          <div className='App-header flex flex-row w-full justify-center'>
            <div className='flex flex-col max-w-screen-xl w-full px-4'>
              <div className='flex flex-row w-full justify-center'>{pageInfo}</div>
              <div className='flex flex-row w-full justify-center'>
                <p className='text-2xl'>Choose the template you want to use</p>
              </div>
              <div className='grid grid-cols-3 gap-5 py-20 justify-center'>{renderLPTypes()}</div>
            </div>
            {showModal && (
              <div className='modal'>
                <div className='modal-content'>
                  <div className='w-full d-flex justify-content-end'>
                    <button
                      className='close'
                      type='button'
                      tabIndex='0'
                      onClick={() => {
                        handleCloseModal();
                      }}
                    >
                      &times;
                    </button>
                  </div>
                  <div className='inputContainer'>
                    <label htmlFor='lpName' className='grid text-black'>
                      Name *
                      <input
                        id='lpName'
                        type='text'
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder='Enter a unique LP name...'
                      />
                    </label>
                  </div>
                  {error && <div className='error'>{error}</div>}
                  <button type='button' className='nextButton' onClick={handleNextClick}>
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
}

export default HomeScreen;
