function EventBuilderFooter({ data }) {
  const {
    bmtID,
    bGroup,
    countryLV,
    customFields,
    eventTY,
    eventTYURL,
    eventDate,
    eventTimeZone,
    campaignName,
    topic,
  } = data;

  const setTimeZone = {
    ET: 'let currentTZDate = new Date(currentDate.toLocaleString("en-US", { timeZone: "America/New_York" }));',
    CT: 'let currentTZDate = new Date(currentDate.toLocaleString("en-US", { timeZone: "America/Chicago" }));',
    MT: 'let currentTZDate = new Date(currentDate.toLocaleString("en-US", { timeZone: "America/Denver" }));',
    PT: 'let currentTZDate = new Date(currentDate.toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));',
    GMT: 'let currentTZDate = new Date(currentDate.toLocaleString("en-US", { timeZone: "GMT" }));',
    CET: 'let currentTZDate = new Date(currentDate.toLocaleString("en-US", { timeZone: "Europe/Paris" }));',
    IST: 'let currentTZDate = new Date(currentDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));',
    JST: 'let currentTZDate = new Date(currentDate.toLocaleString("en-US", { timeZone: "Asia/Tokyo" }));',
    AEST: 'let currentTZDate = new Date(currentDate.toLocaleString("en-US", { timeZone: "Australia/Sydney" }));',
    NZST: 'let currentTZDate = new Date(currentDate.toLocaleString("en-US", { timeZone: "Pacific/Auckland" }));',
  };

  const selectFieldsPadding = customFields
    .map((item) => {
      let result;
      if (item.type === 'select') {
        if (item.extraField === 'Yes' && item.extraFieldType === 'select') {
          result = `if ($('#${item.name}').data('value') != '') {
                $("#${item.name}_sel").addClass("active");

            }
            $("select#${item.name}").css("padding-top", "18px");
            $("#${item.name}").change(function () {
                $("select#${item.name}").css("padding-top", "18px");
            });

            //Extra
            if ($('#${item.extraFieldName}').data('value') != '') {
                $("#${item.extraFieldName}_sel").addClass("active");

            }
            $("select#${item.extraFieldName}").css("padding-top", "18px");
            $("#${item.extraFieldName}").change(function () {
                $("select#${item.extraFieldName}").css("padding-top", "18px");
            });
            `;
        } else {
          result = `if ($('#${item.name}').data('value') != '') {
                $("#${item.name}_sel").addClass("active");

            }
            $("select#${item.name}").css("padding-top", "18px");
            $("#${item.name}").change(function () {
                $("select#${item.name}").css("padding-top", "18px");
            });`;
        }
      } else if (item.extraField === 'Yes' && item.extraFieldType === 'select') {
        result = `
            //Extra
            if ($('#${item.extraFieldName}').data('value') != '') {
                $("#${item.extraFieldName}_sel").addClass("active");

            }
            $("select#${item.extraFieldName}").css("padding-top", "18px");
            $("#${item.extraFieldName}").change(function () {
                $("select#${item.extraFieldName}").css("padding-top", "18px");
            });
        `;
      }
      return result;
    })
    .join('');

  const selectFieldsArrow = customFields
    .map((item) => {
      let result;
      if (item.type === 'select') {
        if (item.extraField === 'Yes' && item.extraFieldType === 'select') {
          result = `
            document.getElementById("${item.name}").addEventListener("click", function () {
                document.getElementById("select-${item.name}-arrow").style.transform = "rotate(180deg)"
            });
            document.getElementById("${item.name}").addEventListener("change", function () {
                document.getElementById("select-${item.name}-arrow").style.transform = "rotate(0deg)"
            });
            $('#${item.name}').focusout(function () {
                document.getElementById("select-${item.name}-arrow").style.transform = "rotate(0deg)"
            });

            // Extra
            document.getElementById("${item.extraFieldName}").addEventListener("click", function () {
                document.getElementById("select-${item.extraFieldName}-arrow").style.transform = "rotate(180deg)"
            });
            document.getElementById("${item.extraFieldName}").addEventListener("change", function () {
                document.getElementById("select-${item.extraFieldName}-arrow").style.transform = "rotate(0deg)"
            });
            $('#${item.extraFieldName}').focusout(function () {
                document.getElementById("select-${item.extraFieldName}-arrow").style.transform = "rotate(0deg)"
            });`;
        } else {
          result = `document.getElementById("${item.name}").addEventListener("click", function () {
                document.getElementById("select-${item.name}-arrow").style.transform = "rotate(180deg)"
            });
            document.getElementById("${item.name}").addEventListener("change", function () {
                document.getElementById("select-${item.name}-arrow").style.transform = "rotate(0deg)"
            });
            $('#${item.name}').focusout(function () {
                document.getElementById("select-${item.name}-arrow").style.transform = "rotate(0deg)"
            });`;
        }
      } else if (item.extraField === 'Yes' && item.extraFieldType === 'select') {
        result = `
        // Extra
        document.getElementById("${item.extraFieldName}").addEventListener("click", function () {
            document.getElementById("select-${item.extraFieldName}-arrow").style.transform = "rotate(180deg)"
        });
        document.getElementById("${item.extraFieldName}").addEventListener("change", function () {
            document.getElementById("select-${item.extraFieldName}-arrow").style.transform = "rotate(0deg)"
        });
        $('#${item.extraFieldName}').focusout(function () {
            document.getElementById("select-${item.extraFieldName}-arrow").style.transform = "rotate(0deg)"
        });`;
      }
      return result;
    })
    .join('');

  const textareaScripts = customFields
    .map((item) => {
      let result;
      if (item.type === 'textarea') {
        if (item.extraField === 'Yes' && item.extraFieldType === 'textarea') {
          result = `
            $('#${item.name}').keyup(function () {

                var characterCount = $(this).val().length,
                    current = $('#current${item.name}'),
                    maximum = $('#maximum${item.name}'),
                    theCount = $('#the-count${item.name}');
    
                current.text(characterCount);
    
            });
            
            // Extra
            $('#${item.name}Extra').keyup(function () {

                var characterCount = $(this).val().length,
                    current = $('#current${item.name}Extra'),
                    maximum = $('#maximum${item.name}Extra'),
                    theCount = $('#the-count${item.name}Extra');
    
                current.text(characterCount);
    
            });`;
        } else {
          result = `$('#${item.name}').keyup(function () {

                var characterCount = $(this).val().length,
                    current = $('#current${item.name}'),
                    maximum = $('#maximum${item.name}'),
                    theCount = $('#the-count${item.name}');
    
                current.text(characterCount);
    
            });`;
        }
      }
      return result;
    })
    .join('');

  let tyScript;
  if (eventTY === 'message') {
    tyScript = `$(".submit-before").hide();
        $(".submit-after").show();
        document.getElementById('head_2').scrollIntoView({ behavior: 'smooth' }, true);`;
  } else tyScript = `window.location.replace("${eventTYURL}");`;

  // Constructing the code
  const code = `
    <!-- Typeahead JS -->
    <script src="https://cdnjs.cloudflare.com/ajax/libs/typeahead.js/0.11.1/typeahead.bundle.min.js"
        integrity="sha512-qOBWNAMfkz+vXXgbh0Wz7qYSLZp6c14R0bZeVX2TdQxWpuKr6yHjBIM69fcF8Ve4GUX6B6AKRQJqiiAmwvmUmQ=="
        crossorigin="anonymous" referrerpolicy="no-referrer"></script>
    <!-- Account Matching -->
    <script
        src="https://images.marketingcontent.ext.hp.com/Web/HPMartech/{7131fbd5-97b2-49f4-bded-bab828c1825e}_account_matching.js"></script>

    <script
        src="https://images.marketingcontent.ext.hp.com/Web/HPMartech/{2e7d9676-fbc2-4e09-830b-d6919e0ff30e}_main.js"
        type="text/javascript"></script>

    <!--LABEL ABOVE SCRIPT for company with account identification-->
    <script>
        function activateInput() {
            let input = $(this).find(':input');
            let label = $('label[for=' + input.attr('id') + ']');
            if (!input.val() && !input.is(":focus")) {
                label.removeClass("active-label");
            } else {
                label.addClass("active-label");
            }
        }
        $(".floatingLabel").focusin(activateInput);
        $(".floatingLabel").focusout(activateInput);

        $(document).ready(function () {
            $(".floatingLabel").each(activateInput);
        });     
    </script>
    <script>
        $('#country').on('change', function () {
            $('#busPhoneChange').addClass('active');
            $('#busPhoneChange').addClass('focused');
        });

        $("#emailAddress").focusout(function () {
            //alert("email focusedOUT");
            var emailVal = $("#emailAddress").val();
            var emDom = emailVal.split('@')[1];
            if (emDom == "mailinator.com") {
                $("#emailAddress").closest('.field-container').addClass('field-error');
                $("#emailAddress").addClass("error");
            } else if (emDom != "mailinator.com") {
                $("#emailAddress").closest('.field-container').removeClass('field-error');
                $("#emailAddress").removeClass("error");
            }
        })

        ${textareaScripts}
    </script>

    <!-- LABEL ABOVE SCRIPT -->
    <script type="text/javascript">

        $(document).ready(function () {

            $("select#country").css("padding-top", "18px");
            if ($('#country').data('value') != '') {
                $("#country_sel").addClass("active");

            }
            $("#country").change(function () {
                $("select#country").css("padding-top", "18px");
            });

            if ($('#stateProv').data('value') != '') {
                $("#stateProv_sel").addClass("active");

            }
            $("select#stateProv").css("padding-top", "18px");
            $("#stateProv").change(function () {
                $("select#stateProv").css("padding-top", "18px");
            });

            ${selectFieldsPadding}


            document.body.addEventListener('mousedown', function () {
                document.body.classList.add('mouse-focus');
            });
            document.body.addEventListener('keydown', function () {
                document.body.classList.remove('mouse-focus');
            });
        });


        function handleFocus1(e) {
            target = e.target;
            target.parentNode.classList.add('active');
            target.parentNode.classList.add('focused');
            target.setAttribute('placeholder', target.getAttribute('data-placeholder') || "");
        }

        function handleBlur(e) {
            target = e.target;
            if (!target.value) {
                if (target.tagName.toLowerCase() != 'select') {
                    target.parentNode.classList.remove('active');
                }
            }
            target.parentNode.classList.remove('focused');
            target.removeAttribute('placeholder');
        }

        function bindEvents(element) {
            floatField = element.querySelector('input, select');
            floatField.addEventListener('focus', handleFocus1);
            floatField.addEventListener('blur', handleBlur);
        }

        function initFloatLabel() {
            floatContainers = document.querySelectorAll('.float-container');
            for (var i = 0, len = floatContainers.length; i < len; i++) {
                //console.log(floatContainers[i]) 
                if (floatContainers[i].querySelector('input, select').value) {
                    if (floatContainers[i].querySelector('input') && floatContainers[i].querySelector('input').name != 'company'){
                        floatContainers[i].classList.add('active');
                    } 
                }
                bindEvents(floatContainers[i]);
            }

        };

        initFloatLabel();


    </script>


    <script>
        $(document).ready(function () {
            document.getElementById("country").addEventListener("click", function () {
                document.getElementById("select-country-arrow").style.transform = "rotate(180deg)"
            });
            document.getElementById("country").addEventListener("change", function () {
                document.getElementById("select-country-arrow").style.transform = "rotate(0deg)"
            });
            $('#country').focusout(function () {
                document.getElementById("select-country-arrow").style.transform = "rotate(0deg)"
            });

            document.getElementById("stateProv").addEventListener("click", function () {
                document.getElementById("select-state-arrow").style.transform = "rotate(180deg)"
            });
            document.getElementById("stateProv").addEventListener("change", function () {
                document.getElementById("select-state-arrow").style.transform = "rotate(0deg)"
            });
            $('#stateProv').focusout(function () {
                document.getElementById("select-state-arrow").style.transform = "rotate(0deg)"
            });

            ${selectFieldsArrow}

        });
    </script>

    <!-- COUNTRY and PRIVACY SCRIPTS -->
    <script>

        $(document).ready(function () {

            $('#country option[value="${countryLV}"]').attr("selected", "selected");
            addCountryCode();

            if ($("#country").val() == "United States") {
                document.getElementById('stateField').style.display = 'block';
                document.getElementById('stateLabel').innerHTML = 'State *';
                document.getElementById('showState').style.display = 'block';
                document.getElementById('stateProv').setAttribute('required', '');
                document.getElementById('stateProv').name = 'stateProv';
                $('.no-display-state').hide();
                $('.caState').attr("disabled", "disabled");
                $('.inState').attr("disabled", "disabled");
                if (!($('.caState').parent().is('span'))) $('.caState').wrap('<span>');
                if (!($('.inState').parent().is('span'))) $('.inState').wrap('<span>');
                if (($('.usState').parent().is('span'))) $('.usState').unwrap();
                $('.usState').removeAttr("disabled");
                $('.usState').show();

            } else if ($("#country").val() == "Canada") {
                document.getElementById('stateField').style.display = 'block';
                document.getElementById('stateLabel').innerHTML = 'Province *';
                document.getElementById('showState').style.display = 'block';
                document.getElementById('stateProv').setAttribute('required', '');
                document.getElementById('stateProv').name = 'stateProv';
                $('.no-display-state').hide();
                $('.usState').attr("disabled", "disabled");
                $('.inState').attr("disabled", "disabled");
                if (!($('.usState').parent().is('span'))) $('.usState').wrap('<span>');
                if (!($('.inState').parent().is('span'))) $('.inState').wrap('<span>');
                if (($('.caState').parent().is('span'))) $('.caState').unwrap();
                $('.caState').removeAttr("disabled");
                $('.caState').show();

            } else if ($("#country").val() == "India") {
                document.getElementById('stateField').style.display = 'block';
                document.getElementById('stateLabel').innerHTML = 'State *';
                document.getElementById('showState').style.display = 'block';
                document.getElementById('stateProv').setAttribute('required', '');
                document.getElementById('stateProv').name = 'stateProv';
                $('.no-display-state').hide();
                $('.usState').attr("disabled", "disabled");
                $('.caState').attr("disabled", "disabled");
                if (!($('.usState').parent().is('span'))) $('.usState').wrap('<span>');
                if (!($('.caState').parent().is('span'))) $('.caState').wrap('<span>');
                if (($('.inState').parent().is('span'))) $('.inState').unwrap();
                $('.inState').removeAttr("disabled");
                $('.inState').show();

            }else {
                document.getElementById('stateProv').name = 'novalue';
                document.getElementById('stateField').style.display = 'none';
                document.getElementById('stateProv').removeAttribute('required');
                $('.no-display-state').hide();
                $('.usState').attr("disabled", "disabled");
                $('.caState').attr("disabled", "disabled");
                $('.inState').attr("disabled", "disabled");
                if (!($('.usState').parent().is('span'))) $('.usState').wrap('<span>');
                if (!($('.caState').parent().is('span'))) $('.caState').wrap('<span>');
                if (!($('.inState').parent().is('span'))) $('.inState').wrap('<span>');
            }

            document.getElementById('country').addEventListener('change', (event) => {
                if (event.target.value === "United States") {
                    document.getElementById('stateLabel').innerHTML = 'State *';
                    document.getElementById('showState').style.display = 'block';
                    document.getElementById('stateProv').setAttribute('required', '');
                    document.getElementById('stateProv').name = 'stateProv';
                    document.getElementById('stateField').style.display = 'block';
                    document.getElementById('stateProv').selectedIndex = 0;
                    $('.no-display-state').hide();
                    $('.caState').attr("disabled", "disabled");
                    $('.inState').attr("disabled", "disabled");
                    if (!($('.caState').parent().is('span'))) $('.caState').wrap('<span>');
                    if (!($('.inState').parent().is('span'))) $('.inState').wrap('<span>');
                    if (($('.usState').parent().is('span'))) $('.usState').unwrap();
                    $('.usState').removeAttr("disabled");
                    $('.usState').show();

                } else if (event.target.value === "Canada") {
                    document.getElementById('stateLabel').innerHTML = 'Province *';
                    document.getElementById('showState').style.display = 'block';
                    document.getElementById('stateProv').setAttribute('required', '');
                    document.getElementById('stateProv').name = 'stateProv';
                    document.getElementById('stateField').style.display = 'block';
                    document.getElementById('stateProv').selectedIndex = 0;
                    $('.no-display-state').hide();
                    $('.usState').attr("disabled", "disabled");
                    $('.inState').attr("disabled", "disabled");
                    if (!($('.usState').parent().is('span'))) $('.usState').wrap('<span>');
                    if (!($('.inState').parent().is('span'))) $('.inState').wrap('<span>');
                    if (($('.caState').parent().is('span'))) $('.caState').unwrap();
                    $('.caState').removeAttr("disabled");
                    $('.caState').show();
                } else if (event.target.value === "India") {
                    document.getElementById('stateLabel').innerHTML = 'State *';
                    document.getElementById('showState').style.display = 'block';
                    document.getElementById('stateProv').setAttribute('required', '');
                    document.getElementById('stateProv').name = 'stateProv';
                    document.getElementById('stateField').style.display = 'block';
                    document.getElementById('stateProv').selectedIndex = 0;
                    $('.no-display-state').hide();
                    $('.usState').attr("disabled", "disabled");
                    $('.caState').attr("disabled", "disabled");
                    if (!($('.usState').parent().is('span'))) $('.usState').wrap('<span>');
                    if (!($('.caState').parent().is('span'))) $('.caState').wrap('<span>');
                    if (($('.inState').parent().is('span'))) $('.inState').unwrap();
                    $('.inState').removeAttr("disabled");
                    $('.inState').show();
                }else {
                    document.getElementById('stateProv').name = 'novalue';
                    document.getElementById('stateField').style.display = 'none';
                    document.getElementById('showState').style.display = 'none';
                    document.getElementById('stateProv').removeAttribute('required');
                    document.getElementById('stateProv').selectedIndex = 0;
                    $('.no-display-state').hide();
                    $('.usState').attr("disabled", "disabled");
                    $('.caState').attr("disabled", "disabled");
                    $('.inState').attr("disabled", "disabled");
                    if (!($('.usState').parent().is('span'))) $('.usState').wrap('<span>');
                    if (!($('.caState').parent().is('span'))) $('.caState').wrap('<span>');
                    if (!($('.inState').parent().is('span'))) $('.inState').wrap('<span>');
                }
            });


            // PRIVACY

            if ($("#country").val() === "United States") {
                $("#masterEmailOptin1").prop("checked", true);
                $("#masterPhoneOptin1").prop("checked", true);
                $("#masterMailOptin1").prop("checked", true);
            } else {
                $("#masterEmailOptin1").prop("checked", false);
                $("#masterPhoneOptin1").prop("checked", false);
                $("#masterMailOptin1").prop("checked", false);
            }
            $("#country").change(function () {
                if ($(this).val() === "United States") {
                    $("#masterEmailOptin1").prop("checked", true);
                    $("#masterPhoneOptin1").prop("checked", true);
                    $("#masterMailOptin1").prop("checked", true);
                } else {
                    $("#masterEmailOptin1").prop("checked", false);
                    $("#masterPhoneOptin1").prop("checked", false);
                    $("#masterMailOptin1").prop("checked", false);
                }
            });


            var privValues = $(".privacyVerb").map(function () { return $(this).text().trim().replace('*', ''); }).get().join(";");
            $("#Privacy_Verbiage").val(privValues);
            submittedFrom();
        });
    </script>

    <!-- END OF COUNTRY and PRIVACY SCRIPTS -->

    <script>

        function elqGetCookie(name) { var cookies = document.cookie.split(';'); for (var i = 0; i < cookies.length; i++) { var position = cookies[i].indexOf('='); if (position > 0 && position < cookies[i].length - 1) { var x = cookies[i].substr(0, position); var y = cookies[i].substr(position + 1); x = x.replace(/^\\s+|\\s+$/g, ''); if (x == name) { return unescape(y); } } } return ''; }
        function elqGetCookieSubValue(name, subKey) { var cookieValue = elqGetCookie(name); if (cookieValue == null || cookieValue == '') return ''; var cookieSubValue = cookieValue.split('&'); for (var i = 0; i < cookieSubValue.length; i++) { var pair = cookieSubValue[i].split('='); if (pair.length > 1) { if (pair[0] == subKey) { return pair[1]; } } } return ''; }

    </script>

    <!-- End of progressive profiling script -->

    <!-- Submit scripts area-->

    <!--Submit with REDIRECT TO WEBPAGE-->

    <!-- <script type="text/javascript" src="https://img.en25.com/Web/HPMartechSandbox/{ba91f877-8e24-483d-a2d5-4774cdfb09d2}_submit_with_redirect.js"></script> -->

    <script>
        //comment only the line below if you want to allow submission ONLY WITH BUSINESS EMAIL DOMAINS
        //var free_email_domains = false;
        var activate_em_dom_flag = false;
        var message_submit = true;
        var current_lookup_key = '';
        var campaignType = jQuery('meta[name="campaign_type"]').attr('content');
        var campainTypesMap = { '3D Printing': '3D_Type1', 'Print': 'Print_Type1', 'Personal Systems': 'PS_Type1', 'GSB': 'GSB_Type1', 'Poly': 'Poly_Type1', 'WSS': 'WSS_Type1' };

        function formSubmitAction() {
            if (typeof message_submit != 'undefined') {
                var message_s = '<h1 class="forma title1  py-5 my-5 text-center" style="padding:5px; max-width:800px; margin:auto;"> Thank you for submitting the form!</h1>';
                var message_err = 'Error!';

                var formData = $("form#" + formId).serialize();

                $.ajax({
                    type: 'POST',
                    url: 'https://s704062726.t.eloqua.com/e/f2',
                    data: formData,
                    encode: true,

                })
                    .done(function () {
                        $("#load").html('&nbsp;');
                        $("#load").hide();
                        ${tyScript}

                    })
                    .fail(function () {
                        $("#load").html(message_err);
                    });
            } else {


                $('#submitBtn').attr("disabled", true);
                setTimeout(function () { $("form#" + formId).submit(); }, 300);

            }


        }

        function getEmDomainFlag() {
            var em_domain = jQuery('#emailAddress').val().toLowerCase().split('@')[1];
            current_lookup_key = em_domain;
            if (current_lookup_key && campaignType && campainTypesMap.hasOwnProperty(campaignType)) {
                _elqQ.push(['elqDataLookup', escape('54bc49bccbb14669b6b9827b78237182'), '<Email_Domain1>' + em_domain + '</Email_Domain1>']);
                setTimeout(function () {
                    if (typeof GetElqContentPersonalizationValue != "function" || GetElqContentPersonalizationValue('Email_Domain1') == '' || GetElqContentPersonalizationValue('Email_Domain1') != current_lookup_key) {
                        //fail callback

                        //formSubmitAction();
                    }
                }, 3000);
            }
        }

        function SetElqContent() {
            if (this.GetElqContentPersonalizationValue && GetElqContentPersonalizationValue('Email_Domain1') == current_lookup_key) {
                jQuery('#domainFlag').val(GetElqContentPersonalizationValue(campainTypesMap[campaignType]));
                //formSubmitAction();
            } else {
                //fail callback

                //formSubmitAction();
            }
        }


    </script>


    <!-- Submit with redirect to webpage -->
    <script>
        var formId = $('[name="elqFormName"]').closest('form').attr('id');
        $("#submitBtn").click(function (event) {
            event.preventDefault();
            // trigger the Loader
            $("#submitLoader").show();
            $("#submitBtn").attr('disabled', 'disabled');
            $("#submitBtn").hide();

            var valideateError = validate();

            if (valideateError == false) {
                if (activate_em_dom_flag) {
                    getEmDomainFlag();
                }
                else {
                    formSubmitAction();
                }

            } else {
                // show the button again
                setTimeout(() => {
                    $("#submitLoader").hide();
                    $("#submitBtn").removeAttr('disabled');
                    $("#submitBtn").show();
                }, 2000);

                if (document.getElementById('busPhone').classList.contains('error') == true) {
                    document.getElementById('errBusPhone').style.display = 'block';
                } else {
                    document.getElementById('errBusPhone').style.display = 'none';
                }

                if (document.getElementById('company').classList.contains('error') == true) {
                    $('#company').closest('.float-container').find('label').addClass('error');
                } else {
                    $('#company').closest('.float-container').find('label').removeClass('error');
                }

            }

        });
    </script>

    <!--End of script for submission with REDIRECT-->


    <!-- Country-based phone prefix and list of free email domains - DO NOT DELETE!!!-->
    <script
        src="https://images.marketingcontent.ext.hp.com/Web/HPMartech/{94d57d01-1171-45bc-a95a-939c31f31171}_phone_cc_email_domains_16092020.js"></script>


    <!-- Validation script -->
    <script
        src="https://images.marketingcontent.ext.hp.com/Web/HPMartech/%7B63bebc5b-cb05-428a-b7b7-8d8994655dd9%7D_instructive_validation_visible_full_kr_nospace.js"></script>

    <!-- Script for Countdown Section -->
    <script>
        $(document).ready(function () {
            let intervalId;
            function timeUntilEvent(eventDate) {
                let currentDate = new Date();
                // Adjust the current datetime to Specified Time Zone
                ${setTimeZone[eventTimeZone]}
              
                let eventDateTime = new Date(eventDate);
              	
                let timeDifference = eventDateTime - currentTZDate;
              
                let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                let hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                return { days, hours, minutes, seconds };
            }

            function updateCountdown(eventDate) {
                let { days, hours, minutes, seconds } = timeUntilEvent(eventDate);
                if (days < -1) {
                    document.getElementById('output').innerHTML = '<div class="col-lg-12 align-self-center py-3"><h2 class="forma b-300 size-24 txt-black no-margin">Thank you for your interest. The event has finished and registrations are closed!</h2></div>';
                    $("#load").html('&nbsp;');
                    $("#load").hide();
                    $(".submit-before").hide();
                    clearInterval(intervalId);
                } else if (days === -1) {
                    document.getElementById('output').innerHTML = '<div class="col-lg-12 align-self-center py-3"><h2 class="forma b-300 size-24 txt-black no-margin">The event is happening right now!</h2></div>';
                    $("#load").html('&nbsp;');
                    $("#load").hide();
                    $(".submit-before").hide();
                    clearInterval(intervalId);
                } else {
                    document.getElementById("days").innerHTML = \`\${days} <br> <span class="forma b-300 size-20">days</span>\`;
                    document.getElementById("hours").innerHTML = \`\${hours} <br> <span class="forma b-300 size-20">hours</span>\`;
                    document.getElementById("minutes").innerHTML = \`\${minutes} <br> <span class="forma b-300 size-20">minutes</span>\`;
                    document.getElementById("seconds").innerHTML = \`\${seconds} <br> <span class="forma b-300 size-20">seconds</span>\`;
                }
            }

            function main() {
                let eventDate = new Date("${eventDate}");
                updateCountdown(eventDate);
              
                // Update the countdown every second
                intervalId = setInterval(function () {
                    updateCountdown(eventDate);
                }, 1000);
            }

            main();
        })
    </script>
    <!-- End of Script for Countdown Section -->

    <script>
        $(document).ready(function () {
            var elqTrackImg, elqLPID = '';
            function getValFromURL(name, url) { name = name.replace(/[\\[]/, '\\\\\\[').replace(/[\\]]/, '\\\\\\]'); var regexS = '[\\\\?&]' + name + '=([^&#]*)'; var regex = new RegExp(regexS); var results = regex.exec(url); if (results == null) { return ''; } else { return results[1]; } }

            setTimeout(function () {
                elqTrackImg = $(document).find('img[src*="LandingPageID"]');

                if (elqTrackImg.length > 0) {
                    elqLPID = '-' + getValFromURL('LandingPageID', elqTrackImg.attr('src'));
                }
                dataLayer.push({
                    event: 'e_pageView',        //Indicates page view (required)
                    pageNameL5: '${bGroup}',   //page name hierarchy level 5 (recommended)
                    pageNameL6: '${campaignName}',   //page name hierarchy level 6 (optional)
                    pageNameL7: '${topic}',   //page name hierarchy level 7 (optional)
                    pageNameL8: '${bmtID}' + '-' + elqLPID    //page name hierarchy level 8 (optional)
                });

            }, 1000);
        });
    </script>

</body>

</html>`;

  return code;
}

export default EventBuilderFooter;
