import 'firebase/compat/database';
import { INITIALIZE_DATA } from './types';
import createDataContext from './createDataContext';

const INITIAL_STATE = {
  loading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE_DATA:
      return { ...action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const { GeneralContext, GeneralProvider } = createDataContext(reducer, {}, INITIAL_STATE);
