import React, { useReducer } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { INITIALIZE_DATA } from './types';

const createDataContext = (reducer, actions, defaultValue) => {
  const GeneralContext = React.createContext();
  function GeneralProvider({ children, isLoggedIn }) {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    React.useEffect(() => {
      if (isLoggedIn === true) {
        firebase
          .database()
          .ref('/content')
          .once('value', (snapshot) => {
            dispatch({ type: INITIALIZE_DATA, payload: snapshot.val() });
          });
      }
    }, [isLoggedIn]);

    return <GeneralContext.Provider value={state}>{children}</GeneralContext.Provider>;
  }

  return { GeneralContext, GeneralProvider };
};

export default createDataContext;
