import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import 'firebase/compat/auth';
import './NavBar.css';
import { useUserContext } from '../../contexts/UserProvider/UserProvider';

function NavBar() {
  const { user, handleLogout } = useUserContext();
  const location = useLocation();

  if (location.pathname === '/login' || location.pathname === '/register') {
    return null;
  }

  return (
    <div className='flex-1 flex-row justify-center w-full navBarContainer'>
      {user.access === 'approved' ? (
        <div className='flex flex-1 flex-row w-full max-w-screen-xl pt-2 pb-2 justify-between'>
          <div className='flex flex-1 flex-grow relative justify-between items-center'>
            <div className='flex items-center flex-row'>
              <nav className='nav'>
                <ul className='flex nav__menu py-2'>
                  <li className='nav__menu-item ml-4 mr-6'>
                    <img
                      alt=''
                      src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/logo_nav.png?alt=media&token=0acae5c9-f92e-46cf-9fa5-601bdba5213f'
                    />
                  </li>
                  <li className='nav__menu-item ml-4 mr-6'>
                    <Link to='/home' className='text-xl'>
                      Home
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className='flex flex-row self-center navBar'>
            <div className='flex flex-1 justify-end'>
              <div className='flex flex-row  justify-center  items-center '>
                <Link to='/user-profile' className='text-md flex nav__menu-item  items-center'>
                  User Profile
                  <img
                    alt=''
                    className='mr-4 ml-4 w-8 rounded-3xl'
                    src='https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B594a4b07-89f2-4132-9c81-b12aa48f141e%7D_profileIcon.png'
                  />
                </Link>

                <button
                  type='button'
                  onClick={() => {
                    handleLogout();
                  }}
                  aria-hidden='true'
                  className='flex flex-row  justify-center nav__menu-item items-center mr-4 logoutContainer'
                >
                  <h3 className='text-md mr-2'>Log out</h3>
                  <img
                    alt=''
                    className='log-out-icon'
                    src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/log-out-icon-white.png?alt=media&token=008b7178-b098-43ed-a23a-81c8164bc242'
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default NavBar;
