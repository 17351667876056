import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import './ForgotPassword.css';
import { useUserContext } from '../../contexts/UserProvider/UserProvider';

function ForgotPassword() {
  const { isLoggedIn } = useUserContext();
  const [forgotPasswordData, changeForgotPasswordData] = React.useState({
    email: '',
    password: '',
  });
  const [loading, changeLoadingState] = React.useState(false);
  const [passReset, changePassReset] = React.useState(false);
  const [invalidMail, changeInvalidMail] = React.useState(false);
  const onLoginPress = async () => {
    changeLoadingState(true);
    firebase
      .auth()
      .sendPasswordResetEmail(forgotPasswordData.email)
      .then(() => {
        changePassReset(true);
        changeInvalidMail(false);
        changeLoadingState(false);
      })
      .catch((error) => {
        changeInvalidMail(true);
        changeLoadingState(false);
      });
  };
  return (
    <div className='xl:py-36'>
      {isLoggedIn ? (
        <Navigate to='/home' />
      ) : (
        <div className='flex flex-col items-center justify-center items-center w-full px-6'>
          <img
            className='mb-6 w-full max-w-sm md:max-w-xl mt-20 xl:mt-0'
            alt=''
            src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/log_logo(1).png?alt=media&token=f4c0f0c9-dcf2-4f4a-8b43-71f69271367d'
          />
          <h1 className='text-3xl md:text-5xl text-center self-center text-black'>
            Generic LP Builder
          </h1>
          <p className='text-xl md:text-2xl text-black  text-center my-4'>
            Type here the email address to reset the password.
          </p>
          {!passReset ? (
            <input
              placeholder='Email'
              className='emailInput mt-4 rounded-lg'
              type='email'
              onChange={(e) => {
                changeForgotPasswordData({ ...forgotPasswordData, email: e.target.value });
              }}
            />
          ) : null}
          {passReset ? (
            <p className='text-xl md:text-xl text-black text-center my-4 mt-4'>
              A password reset email has been sent to this email address. <br /> Please check your
              “junk mail” folder or “spam” folder.
            </p>
          ) : null}
          {invalidMail ? (
            <p className='text-s md:text-s text-black text-center mt-4' style={{ color: 'red' }}>
              Invalid email address.
            </p>
          ) : null}
          {!passReset ? (
            <button type='button' className='button text-md my-4 flex' onClick={onLoginPress}>
              Submit
              {loading === true ? (
                <img
                  alt=''
                  src='https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B9f68b913-5847-4988-aaa4-c17a1c7d6d6a%7D_loading.png'
                />
              ) : null}
            </button>
          ) : null}
          <Link to='/login' className='text-md mt-8 default-link'>
            &lt; &nbsp; Back to log in
          </Link>
        </div>
      )}
    </div>
  );
}

export default ForgotPassword;
