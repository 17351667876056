import React from 'react';
import { Navigate, Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import './RegisterScreen.css';
import { useUserContext } from '../../contexts/UserProvider/UserProvider';
import { USER_REDUCER_INITIAL_STATE } from '../../contexts/UserProvider/UserReducer';

function RegisterScreen() {
  const { isLoggedIn } = useUserContext();
  const [registerData, changeRegisterData] = React.useState({ email: '', password: '' });
  const [loading, changeLoadingState] = React.useState(false);
  const [invalidRegister, changeInvalidRegisterState] = React.useState(false);

  const onRegisterPress = async () => {
    let { email } = registerData;
    email = email.trim();
    // if (email.split("@")[1].toLowerCase() == "hp.com") {
    changeLoadingState(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(registerData.email, registerData.password)
      .then(() => {
        firebase
          .database()
          .ref(`/users/${firebase.auth().currentUser.uid}`)
          .set({ ...USER_REDUCER_INITIAL_STATE.user, email });
      })
      .catch((error) => {
        changeLoadingState(false);
        changeInvalidRegisterState(true);
      });
    // }
    // else {
    //     alert("The email address you have entered is not a hp.com address.")
    // }
  };
  return (
    <div className='xl:py-36'>
      {isLoggedIn ? (
        <Navigate to='/home' />
      ) : (
        <div className='flex flex-col items-center justify-center items-center w-full px-6'>
          <img
            className='mb-6 w-full max-w-sm md:max-w-xl mt-20 xl:mt-0'
            alt=''
            src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/log_logo(1).png?alt=media&token=f4c0f0c9-dcf2-4f4a-8b43-71f69271367d'
          />
          <h1 className='text-3xl md:text-5xl text-center self-center text-black'>
            Generic LP Builder
          </h1>
          <p className='text-xl md:text-2xl text-black  text-center my-4'>
            Enter your work email and preferred password and click SUBMIT
          </p>
          <input
            placeholder='Email'
            className='emailInput my-4 rounded-lg'
            type='email'
            onChange={(e) => {
              changeRegisterData({ ...registerData, email: e.target.value });
            }}
          />
          <input
            type='password'
            placeholder='Password'
            className='passwordInput rounded-lg'
            onChange={(e) => {
              changeRegisterData({ ...registerData, password: e.target.value });
            }}
          />
          {invalidRegister ? (
            <p
              className='text-l md:text-l text-black text-center my-4 mt-4'
              style={{ color: 'red' }}
            >
              Please provide credentials.
            </p>
          ) : null}
          <button className='button text-md my-4 flex' type='button' onClick={onRegisterPress}>
            Submit
            {loading === true ? (
              <img
                alt=''
                src='https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B9f68b913-5847-4988-aaa4-c17a1c7d6d6a%7D_loading.png'
              />
            ) : null}
          </button>
          <Link to='/login' className='text-md mt-8 default-link'>
            &lt; &nbsp; Back to log in
          </Link>
        </div>
      )}
    </div>
  );
}

export default RegisterScreen;
