import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useUserContext } from '../../contexts/UserProvider/UserProvider';
import './LoginScreen.css';

function LoginScreen() {
  const { isLoggedIn } = useUserContext();
  const [loginData, changeLoginData] = React.useState({ email: '', password: '' });
  const [loading, changeLoadingState] = React.useState(false);
  const [invalidLogin, changeInvalidLoginState] = React.useState(false);

  const onLoginPress = async () => {
    changeLoadingState(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(loginData.email, loginData.password)
      .catch((error) => {
        changeLoadingState(false);
        changeInvalidLoginState(true);
      });
  };
  return (
    <div className='xl:py-36'>
      {isLoggedIn ? (
        <Navigate to='/home' />
      ) : (
        <div className='flex flex-col items-center justify-center items-center w-full px-6'>
          <img
            className='mb-6 w-full max-w-sm md:max-w-xl mt-20 xl:mt-0'
            alt=''
            src='https://firebasestorage.googleapis.com/v0/b/hp-generic-lp-builder.appspot.com/o/log_logo(1).png?alt=media&token=f4c0f0c9-dcf2-4f4a-8b43-71f69271367d'
          />
          <h1 className='text-2xl md:text-5xl text-center self-center text-black'>
            Welcome to Generic LP Builder
          </h1>
          <p className='text-xl md:text-2xl text-black text-center my-4 mt-4 '>
            Please log in or request access.
          </p>
          <input
            placeholder='Email'
            className='emailInput my-4 rounded-lg'
            type='email'
            onChange={(e) => {
              changeLoginData({ ...loginData, email: e.target.value });
            }}
          />
          <input
            placeholder='Password'
            className='passwordInput rounded-lg'
            type='password'
            onChange={(e) => {
              changeLoginData({ ...loginData, password: e.target.value });
            }}
          />
          {invalidLogin ? (
            <p
              className='text-l md:text-l text-black text-center my-4 mt-4'
              style={{ color: 'red' }}
            >
              Invalid credentials.
            </p>
          ) : null}
          <button
            type='button'
            className='button text-md mt-4 flex flex-row'
            onClick={onLoginPress}
          >
            Login
            {loading === true ? (
              <img
                alt=''
                src='https://images.marketingcontent.ext.hp.com/EloquaImages/clients/HPMartech/%7B9f68b913-5847-4988-aaa4-c17a1c7d6d6a%7D_loading.png'
              />
            ) : null}
          </button>
          <Link to='/register' className='text-s mt-8 default-link'>
            Request for access here &nbsp; &gt;
          </Link>
          <Link to='/forgot-password' className='text-s mt-3 mb-8 default-link'>
            Forgot password?
          </Link>
        </div>
      )}
    </div>
  );
}

export default LoginScreen;
